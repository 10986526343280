import { jsonFetchPromise } from './FetchHelper'

export default class Patient {

    constructor() {
        this._idPaciente = ''
        this._nombre = ''
        this._apellido = ''
        this._mail = ''
        this._idPrepaga = ''
        this._numeroAsociado = ''
        this._fechaNacimiento = ''
        this._celular = ''
        this._urlImagen = ''
        this._imagenPaciente = ''
        this._cuit = ''
        this._descripcionGeneral = ''
        this._lastConsultation = ''
        this._nombrePrepaga = ''
    }

    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    set nombre(nombre) { this._nombre = nombre }
    set apellido(apellido) { this._apellido = apellido }
    set urlImagen(urlImagen) { this._urlImagen = urlImagen }
    set imagenPaciente(imagenPaciente) { this._imagenPaciente = imagenPaciente }
    set mail(mail) { this._mail = mail }
    set fechaNacimiento(fechaNacimiento) { this._fechaNacimiento = fechaNacimiento }
    set lastConsultation(lastConsultation) { this._lastConsultation = lastConsultation }
    set celular(celular) { this._celular = celular }
    set idPrepaga(idPrepaga) { this._idPrepaga = idPrepaga }
    set numeroAsociado(numeroAsociado) { this._numeroAsociado = numeroAsociado }
    set cuit(cuit) { this._cuit = cuit }
    set descripcionGeneral(descripcionGeneral) { this._descripcionGeneral = descripcionGeneral }
    set nombrePrepaga(nombrePrepaga) { this._nombrePrepaga = nombrePrepaga }

    get idPaciente() { return this._idPaciente }
    get nombre() { return this._nombre }
    get apellido() { return this._apellido }
    get urlImagen() { return this._urlImagen }
    get imagenPaciente() { return this._imagenPaciente }
    get mail() { return this._mail }
    get fechaNacimiento() { return this._fechaNacimiento }
    get lastConsultation() { return this._lastConsultation }
    get celular() { return this._celular }
    get idPrepaga() { return this._idPrepaga }
    get numeroAsociado() { return this._numeroAsociado }
    get cuit() { return this._cuit }
    get descripcionGeneral() { return this._descripcionGeneral }
    get nombrePrepaga() { return this._nombrePrepaga }

    update(jwt) {
        if (!this.idPaciente) {
            return this.createPatient(jwt)
        }
        return this.updateExistingPatient(jwt)
    }

    createFormData() {
        const formData = new FormData();

        let fechaNacimiento = this._fechaNacimiento
        if (fechaNacimiento != '') {
            fechaNacimiento = new Date(this._fechaNacimiento)
            fechaNacimiento.setHours(0, 0, 0)
            fechaNacimiento = fechaNacimiento.toISOString()
        }

        formData.append("nombre", this._nombre)
        formData.append("apellido", this._apellido)
        formData.append("mail", this._mail)
        formData.append("idPrepaga", this._idPrepaga)
        formData.append("numeroAsociado", this._numeroAsociado)
        formData.append("fechaNacimiento", fechaNacimiento)
        formData.append("celular", this._celular)
        formData.append("urlImagen", this._urlImagen)
        formData.append("imagenPaciente", this._imagenPaciente)
        formData.append("cuit", this._cuit)
        formData.append("descripcionGeneral", this._descripcionGeneral)

        return formData
    }

    createPatient(jwt) {
        const formData = this.createFormData()
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes', 'POST', {}, (json) => { }, jwt, formData)
    }

    delete(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente, 'DELETE', {}, (json) => { }, jwt)
    }

    get(jwt) {
        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente,
            'GET',
            { "Accept": "application/json" },
            (json) => {
                console.log(json)
                const obj = json
                Object.assign(this, obj)
            },
            jwt)
    }

    updateExistingPatient(jwt) {
        const formData = this.createFormData()
        console.log(this)
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente, 'PUT', {}, (json) => { }, jwt, formData)
    }

}