import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useContext } from "react";
import { Button, ButtonGroup, Radio } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Archivos from "../classes/Archivos";
import Archivo from "../classes/Archivo";
import AuthContext from "../AuthContext";
import mySwallFetchErrorHandler from "./mySwallFetchErrorHandler";
import LoadsWithSpinner from "./LoadsWithSpinner";
import ConsultationItems from "../classes/ConsultationItems";

export default function ConsultationModal({
  idPaciente,
  idConsulta,
  fechaConsulta,
  show,
  onHide,
  tiposItemConsulta,
  objetivo,
  tratamiento,
}) {
  const [loading, setLoading] = useState(false);
  const [consultationItems, setConsultationItems] = useState([]);

  const { JWT, logoutHandler } = useContext(AuthContext);

  function refreshConsultationItems() {
    const consultationItemsModel = new ConsultationItems();
    consultationItemsModel.idPaciente = idPaciente;
    consultationItemsModel.idConsultation = idConsulta;

    setLoading(true);
    consultationItemsModel
      .getAll(JWT)
      .then(() => {
        setConsultationItems(consultationItemsModel.items);
        console.log(consultationItemsModel.items);
        setLoading(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoading(false);
      });
  }

  useEffect(() => {
    refreshConsultationItems();
  }, [idConsulta]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Consulta {fechaConsulta}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <LoadsWithSpinner variable={loading} text="Cargando items...">
        <div className="card mb-4">
              <div
                className="card-header py-3 border-left-warning"
                style={{ background: "#fff" }}
              >
                <h5 className="text-dark font-weight-bold h5 mb-0">Objetivo</h5>
              </div>
              <div className="card-body" style={{ padding: 0 }}>
                <div
                  dangerouslySetInnerHTML={{ __html: objetivo }}
                  style={{ padding: 20, lineHeight: 1.3 }}
                ></div>
              </div>
            </div>
            <div className="card mb-4">
              <div
                className="card-header py-3 border-left-warning"
                style={{ background: "#fff" }}
              >
                <h5 className="text-dark font-weight-bold h5 mb-0">Tratamiento</h5>
              </div>
              <div className="card-body" style={{ padding: 0 }}>
                <div
                  dangerouslySetInnerHTML={{ __html: tratamiento }}
                  style={{ padding: 20, lineHeight: 1.3 }}
                ></div>
              </div>
            </div>
          {consultationItems.map((item) => (
            <div className="card mb-4">
              <div
                className="card-header py-3 border-left-info"
                style={{ background: "#fff" }}
              >
                <h5 className="text-dark font-weight-bold h5 mb-0">
                  {
                    tiposItemConsulta.find(
                      (tipo) =>
                        tipo.idTipoItemConsulta == item.idTipoItemConsulta
                    ).titulo
                  }
                </h5>
              </div>
              <div className="card-body" style={{ padding: 0 }}>
                <div
                  dangerouslySetInnerHTML={{ __html: item.body }}
                  style={{ padding: 20, lineHeight: 1.3 }}
                ></div>
              </div>
            </div>
          ))}
        </LoadsWithSpinner>
      </Modal.Body>
    </Modal>
  );
}
