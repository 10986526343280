import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'
import LoadsWithSpinner from './LoadsWithSpinner';
import { jsonFetchPromise } from '../classes/FetchHelper'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Estudio from '../classes/Estudio';
import TiposCampo from '../classes/TiposCampo';
import CampoModal from './CampoModal'
import {
    useHistory,
    Link,
    useParams
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const MySwal = withReactContent(Swal)

export default function EstudioEdit({ patient }) {
    const { JWT, logoutHandler } = useContext(AuthContext)
    let history = useHistory();
    let { idEstudio } = useParams()
    const [loadingEstudio, setLoadingEstudio] = useState(true)
    const [estudio, setEstudio] = useState(null)
    const [loadingTipos, setLoadingTipos] = useState(true)
    const [tiposCampoEstudio, setTiposCampoEstudio] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalCampo, setModalCampo] = useState(false);
    const [loadingCampo, setLoadingCampo] = useState(false)

    const getEstudio = () => {
        setLoadingEstudio(true)
        const estudio = new Estudio(idEstudio, patient.idPaciente)
        estudio.get(JWT)
            .then(() => {
                estudio.getCampos(JWT).then(() => {
                    setEstudio(estudio)
                    console.log(estudio.campos())
                    setLoadingEstudio(false)
                })
                    .catch((error) => {
                        mySwallFetchErrorHandler(error, logoutHandler);
                        setLoadingEstudio(false)
                    });
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoadingEstudio(false)
            });
    }

    //Si, repeti logica con el de abajo :D
    const getTiposCampoEstudio = () => {
        setLoadingTipos(true)
        const tiposCampoEstudioRepo = new TiposCampo(estudio.tipoEstudio().idTipoEstudio)
        tiposCampoEstudioRepo.getAll(JWT)
            .then(() => {
                setTiposCampoEstudio(tiposCampoEstudioRepo.tiposCampoEstudio)
                setLoadingTipos(false)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoadingTipos(false)
            });
    }

    const refreshTiposCampoEstudio = () => {
        const tiposCampoEstudioRepo = new TiposCampo(estudio.tipoEstudio().idTipoEstudio)
        tiposCampoEstudioRepo.getAll(JWT)
            .then(() => {
                setTiposCampoEstudio(tiposCampoEstudioRepo.tiposCampoEstudio)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
            });
    }

    const submitHandler = (values) => {
        setLoading(true)
        const newEstudio = new Estudio(idEstudio, patient.idPaciente, values.fechaEstudio, values.tipoEstudio, values.descripcion, values.archivoEstudio, null, values.comentarios)
        newEstudio.updateResultados(JWT, values.resultados.map(resultado => { return { idCampoEstudio: resultado.idTipoCampo, valor: resultado.valor, positivo: resultado.positivo } })).then((respuesta) => {
            newEstudio.editar(JWT).then((respuesta) => {
                MySwal.fire({
                    icon: 'success',
                    text: "Estudio editado correctamente!",
                    confirmButtonText: 'Ok!'
                }).then((result) => {
                    if (result.value) {
                        history.push("/pacientes/" + patient.idPaciente + "/estudios")
                    }
                })
                setLoading(false)
            }).catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setLoading(false)
            })
        }).catch((error) => {
            mySwallFetchErrorHandler(error, logoutHandler)
            setLoading(false)
        })
    }

    const crearCampo = (values, idTipoEstudio) => {
        setLoadingCampo(true)
        setModalCampo(false)
        const promise = jsonFetchPromise('https://api.medicinafuncional.com.ar/tiposestudio/' + idTipoEstudio + '/camposestudio', 'POST', {}, (json) => { }, JWT, JSON.stringify({ titulo: values.titulo, unidad: values.unidad }))
        promise.then((respuesta) => {
            MySwal.fire({
                icon: 'success',
                text: "Campo creado correctamente!",
            })
            setLoadingCampo(false)
            refreshTiposCampoEstudio()
        }).catch((error) => {
            mySwallFetchErrorHandler(error, logoutHandler)
            setLoadingCampo(false)
        })
    }

    useEffect(() => {
        if (estudio) {
            getTiposCampoEstudio()
        }
    }, estudio)


    useEffect(() => {
        getEstudio()
    }, [])

    return (
        <div className="container-fluid">
            <div className="card shadow">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col">
                            <p className="text-primary m-0 font-weight-bold" style={{ fontSize: '25px' }}>Editando estudio <small style={{ color: '#bbbbbb' }}>(*) requerido</small></p>
                        </div>
                        <div className="col-lg-3">
                            <Link onClick={history.goBack}>
                                <h6 className="float-right">Cancelar y volver&nbsp;&nbsp;<button className="btn btn-primary text-center text-white rounded-circle" role="button" style={{ fontSize: '15px' }}><FontAwesomeIcon icon="undo" /></button></h6>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ padding: 20 }}>
                    <div className={"col-sm-1 col-md-1 col-lg-1"}></div>
                    <LoadsWithSpinner variable={!estudio || loadingEstudio || loadingTipos} text="Cargando estudio">
                        <div className={"col-sm-10 col-md-10 col-lg-10"}>
                            {estudio && !loadingTipos && <Formik
                                initialValues={{
                                    descripcion: estudio.descripcion(),
                                    comentarios: estudio.comentarios(),
                                    fechaEstudio: estudio.fecha(),
                                    tipoEstudio: estudio.tipoEstudio().idTipoEstudio,
                                    archivoEstudio: '',
                                    resultados: estudio.campos().map(resultado => { return { idTipoCampo: resultado.tipoCampo().idCampoEstudio, valor: resultado.valor(), positivo: resultado.positivo() } })
                                }}
                                validationSchema={Yup.object({
                                    descripcion: Yup.string()
                                        .nullable(),
                                    comentarios: Yup.string()
                                        .nullable(),
                                    fechaEstudio: Yup.date()
                                        .typeError('Solo se aceptan fechas')
                                        .required('Requerido'),
                                    tipoEstudio: Yup.number()
                                        .required('Requerido'),
                                    archivoEstudio: Yup.string()
                                        .nullable(),
                                    resultados: Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                idTipoCampo: Yup.number(),
                                                valor: Yup.string()
                                                    .nullable()
                                                ,
                                                positivo: Yup.number()
                                                    .nullable()
                                            })
                                        )
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    submitHandler(values)
                                    setSubmitting(false)
                                }}
                                render={({ values, errors }) => (
                                    <Form className="form">
                                        <div className="pricingTable purple">
                                            <div className="pricingTable-header">
                                                <FormikSelect name="tipoEstudio" label="Tipo de estudio (*)" disabled={true} className="form-control">
                                                    <option value={estudio.tipoEstudio().idTipoEstudio} label={estudio.stringTipo()} />
                                                </FormikSelect>
                                                <br />
                                                <FormikDatePicker
                                                    className="form-control"
                                                    label="Fecha del estudio (*)"
                                                    name="fechaEstudio"
                                                />
                                                <br />
                                                <br />
                                                <FormikTextInput
                                                    name="descripcion"
                                                    type="text"
                                                    label="Descripción"
                                                    placeholder=""
                                                    className="form-control"
                                                />
                                                <br />
                                                <FormikTextArea
                                                    name="comentarios"
                                                    label="Comentarios"
                                                    placeholder=""
                                                    className="form-control"
                                                /></div>
                                            <div className="pricingContent">
                                                <FormikFileInput
                                                    className="form-control"
                                                    label={estudio.linkArchivo() ? "Cambiar archivo" : "Adjuntar archivo"}
                                                    name="archivoEstudio"
                                                    anyFile={true}
                                                />
                                                {!values.archivoEstudio && estudio.linkArchivo() ? <a target="_blank" href={estudio.linkArchivo()}>Ver archivo actual</a> : ""}
                                                <hr />
                                            </div>
                                            <br />
                                            <FieldArray
                                                name="resultados"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {values.resultados && values.resultados.length > 0 ? (
                                                            values.resultados.map(({ idTipoCampo, titulo, valor, positivo }, index) => (
                                                                <div key={index} style={{ marginBottom: 10 }}>
                                                                    <div className="row" style={{ padding: 10 }}>
                                                                        <div className="col-md-4" >
                                                                            <h4 style={{ marginTop: 20 }}>{traerTipoCampo(values, index).titulo + " (" + traerTipoCampo(values, index).unidad + ")"}</h4>
                                                                        </div>

                                                                        <div className="col-md-3">
                                                                            <FormikTextInput placeholder="Normal" className="form-control" name={`resultados.${index}.valor`} />
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <FormikSelect name={`resultados.${index}.positivo`} label="" className="form-control">
                                                                                <option value={null} label="Neutral" />
                                                                                <option value={1} label="Positivo" />
                                                                                <option value={0} label="Negativo" />
                                                                            </FormikSelect>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                style={{ marginTop: 20 }}
                                                                                onClick={() => console.log(arrayHelpers.remove(index))} // remove a friend from the list
                                                                            > X </button>
                                                                        </div>
                                                                    </div>
                                                                    <hr />

                                                                </div>
                                                            ))
                                                        ) : null}

                                                        <LoadsWithSpinner variable={loadingCampo}>
                                                            <div className="btn-group dropup">
                                                                <button type="button" className="btn btn-primary text-center text-white rounded-circle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ fontSize: 22, width: 47, height: 47 }}>+</button>
                                                                <div className="dropdown-menu" style={{ fontSize: 17 }}>
                                                                    {tiposCampoEstudio.filter(tipo =>
                                                                        !(values.resultados).map(resultado => resultado.idTipoCampo).includes(tipo.idCampoEstudio)
                                                                    ).map(({ idCampoEstudio, titulo }) => <button type="button" onClick={() => arrayHelpers.push({ idTipoCampo: idCampoEstudio, valor: '', positivo: null })} className="dropdown-item">{titulo}</button>)}
                                                                    <hr />
                                                                    <button type="button" className="dropdown-item" style={{ fontWeight: 'bold' }} onClick={() => setModalCampo(true)}>Crear campo</button>
                                                                </div>
                                                            </div>
                                                        </LoadsWithSpinner>
                                                        <CampoModal
                                                            show={modalCampo}
                                                            onHide={() => setModalCampo(false)}
                                                            idPaciente={patient.idPaciente}
                                                            submitHandler={(values) => { crearCampo(values, estudio.tipoEstudio().idTipoEstudio) }}
                                                        />
                                                        <br />
                                                        <br />
                                                    </div>
                                                )}
                                            />
                                            <LoadsWithSpinner variable={loading}>
                                                <button className="btn btn-info" style={{ width: '100%' }} type="submit">Cargar</button>
                                            </LoadsWithSpinner>
                                        </div>
                                    </Form>)} />
                            }
                        </div>
                    </LoadsWithSpinner>
                    <div className={"col-sm-1 col-md-1 col-lg-1"}></div>
                </div>
            </div>
        </div >
    )

    function traerTipoCampo(values, index) {
        console.log(tiposCampoEstudio, index)
        return tiposCampoEstudio.find(({ idCampoEstudio }) => idCampoEstudio == (values.resultados)[index].idTipoCampo);
    }
}
