import { jsonFetchPromise } from './FetchHelper'

export class Consultation {

    constructor() {
        this._idConsulta = ''
        this._fecha = ''
        this._objetivo = ''
        this._tratamiento = ''
        this._motivo = ''
        this._idPaciente = ''
        this._numeroConsulta = ''
    }

    set idConsulta(idConsulta) { this._idConsulta = idConsulta }
    set fecha(fecha) { this._fecha = fecha }
    set objetivo(objetivo) { this._objetivo = objetivo }
    set tratamiento(tratamiento) { this._tratamiento = tratamiento }
    set motivo(motivo) { this._motivo = motivo }
    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    set numeroConsulta(numeroConsulta) { this._numeroConsulta = numeroConsulta }

    get idConsulta() { return this._idConsulta }
    get fecha() { return this._fecha }
    get objetivo() { return this._objetivo }
    get tratamiento() { return this._tratamiento }
    get motivo() { return this._motivo }
    get idPaciente() { return this._idPaciente }
    get numeroConsulta() { return this._numeroConsulta }

    get fechaString() {
        let fecha = new Date(this.fecha)
        return fecha.toLocaleString()
    }

    update(jwt) {
        if (!this._idConsulta) {
            return this.createConsultation(jwt)
        }
        return this.updateDateAndReason(jwt)
    }

    createFormDataWith(obj) {

        const formData = {
            idConsulta: this._idConsulta,
            idPaciente: this._idPaciente
        }

        return JSON.stringify(Object.assign(formData, obj))
    }

    createCompleteFormData() {
        this._fecha && this.less3hours()
        return this.createFormDataWith({
            fecha: this._fecha,
            objetivo: this._objetivo,
            tratamiento: this._tratamiento,
            motivo: this._motivo
        }
        )
    }

    plus3hours() {
        this._fecha.setHours(this._fecha.getHours() + 3)
    }

    less3hours() {
        this._fecha.setHours(this._fecha.getHours() - 3)
    }

    createConsultation(jwt) {
        const formData = this.createCompleteFormData()
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas', 'POST', {}, (json) => { }, jwt, formData)
    }

    delete(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsulta, 'DELETE', {}, (json) => { }, jwt)
    }

    get(jwt) {
        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsulta,
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const obj = json
                this._idConsulta = obj.idConsulta
                this._fecha = obj.fecha
                this._objetivo = obj.objetivo
                this._tratamiento = obj.tratamiento
                this._motivo = obj.motivo
                this._idPaciente = obj.idPaciente
                this._numeroConsulta = obj.numeroConsulta
            },
            jwt)
    }

    updateDateAndReason(jwt) {
        this.less3hours()
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsulta, 'PUT', {}, (json) => { }, jwt,
            this.createFormDataWith({
                fecha: this._fecha,
                motivo: this._motivo
            }))
    }

    updateTreatment(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsulta, 'PUT', {}, (json) => { }, jwt,
            this.createFormDataWith({ tratamiento: this._tratamiento }))
    }
    updateGoal(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsulta, 'PUT', {}, (json) => { }, jwt,
            this.createFormDataWith({ objetivo: this._objetivo }))
    }

}
export default Consultation