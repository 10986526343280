import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Destacado as DestacadoModel } from '../classes/Destacado'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'
import LoadsWithSpinner from './LoadsWithSpinner'
import { Formik, Form } from 'formik';
import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'
import { Collapse, Button, CardBody, Card } from 'reactstrap';

import * as Yup from 'yup';
import {
    Link
} from "react-router-dom";
export const Destacados = ({ idPaciente }) => {

    const { JWT, logoutHandler } = useContext(AuthContext)

    const [itemsDestacados, setItemsDestacados] = useState([])
    const [loading, setLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [showAddCustom, setShowAddCustom] = useState(false)


    const alertTypes = {
        'item': 'info',
        'custom': 'warning',
        'hito': 'danger'
    }

    const refreshItems = () => {
        const destacadoModel = new DestacadoModel();
        destacadoModel.idPaciente = idPaciente
        setLoading(true);
        destacadoModel.getAll(JWT)
            .then(() => {
                setItemsDestacados(destacadoModel.destacados);
                setLoading(false);
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoading(false);
            });
    }

    const deleteDestacado = (id, tipo) => {
        const destacadoModel = new DestacadoModel()
        destacadoModel.idDestacado = id
        destacadoModel.idPaciente = idPaciente
        destacadoModel.tipoDestacado = tipo
        const prevItems = itemsDestacados
        setItemsDestacados(previous =>
            previous.filter(item => (item.idDestacado !== id || item.tipoDestacado !== tipo))
        )
        destacadoModel.delete(JWT)
            .then((respuesta) => {
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setItemsDestacados(prevItems)
            })
    }

    const submitHandler = (values) => {
        setShowAddCustom(false)
        const destacadoModel = new DestacadoModel();
        destacadoModel.idPaciente = idPaciente
        destacadoModel.titulo = values.titulo
        destacadoModel.razon = values.razon
        setAddLoading(true);
        destacadoModel.addCustom(JWT).then((respuesta) => {
            refreshItems()
            setAddLoading(false);
        }).catch((error) => {
            mySwallFetchErrorHandler(error, logoutHandler)
            setAddLoading(false);
        })
    }

    const NewDestacadoForm = () => {
        return (
            <Formik
                initialValues={{
                    titulo: '',
                    razon: ''
                }}
                validationSchema={Yup.object({
                    titulo: Yup.string()
                        .required('Requerido'),
                    razon: Yup.string()
                })}
                onSubmit={(values, { setSubmitting }) => {
                    submitHandler(values)
                    setSubmitting(false)
                }}
            >
                <Form className="form">
                    <div className="row">
                        <div className="col">
                            <FormikTextInput
                                label="Titulo"
                                name="titulo"
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <FormikTextInput
                                label="Razón"
                                name="razon"
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </div>
                    </div>
                    <br />
                    <button className="btn btn-info btn-small">Agregar</button>

                </Form>
            </Formik>
        )
    }

    useEffect(() => {
        refreshItems()
    }, [])

    const WithLink = ({ idConsulta, children }) => {
        if (idConsulta) {
            return (
                <Link to={"/pacientes/" + idPaciente + '/consultas/' + idConsulta} style={{ textDecoration: 'none' }}>
                    {children}
                </Link>
            )
        }

        return children
    }

    const ItemDestacado = ({ titulo, fecha, texto, icono, alertType, idDestacado, tipoDestacado, idConsulta }) =>
        <div className="col-md-6 col-xl-3 mb-4">
            <div className={"card shadow border-left-" + alertType + " py-2"}>
                <FontAwesomeIcon onClick={() => deleteDestacado(idDestacado, tipoDestacado)} icon="times-circle" style={{ position: 'absolute', float: 'right', top: 0, right: 0, margin: 10, cursor: 'pointer' }} />
                <WithLink idConsulta={idConsulta}>
                    <div className="card-body" >
                        <div className="row align-items-center no-gutters">
                            <div className="col mr-2">
                                <div className={"text-uppercase text-success text-" + alertType + " font-weight-bold text-lg mb-1"}><span>{titulo}</span></div>
                                <div className="text-uppercase text-success font-weight-bold text-xs mb-1"><span style={{ color: 'rgb(179,179,179)' }}>{fecha}</span></div>
                                <div className="text-dark font-weight-bold h5 mb-0"><span>{texto}</span></div>
                            </div>
                            {icono && <div className="col-lg-1"><FontAwesomeIcon icon="calendar" /></div>}
                        </div>
                    </div>
                </WithLink>
            </div>
        </div>

    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="text-primary m-0 font-weight-bold"><FontAwesomeIcon icon="star" /> Destacados</h6>
            </div>
            <div className="card-body">
                <div className="row">
                    <LoadsWithSpinner variable={loading} texto="Cargando destacados">
                        {
                            itemsDestacados.map(item => <ItemDestacado idConsulta={item.idConsulta} tipoDestacado={item.tipoDestacado} idDestacado={item.idDestacado} titulo={item.titulo} fecha={item.fecha.toLocaleDateString()} texto={item.razon} alertType={alertTypes[item.tipoDestacado]} icono={item.tipoDestacado === 'hito'} />)
                        }
                    </LoadsWithSpinner>
                </div>
                <div className="col text-center">
                    <LoadsWithSpinner variable={addLoading}>
                        <a onClick={() => setShowAddCustom(prev => !prev)} className="btn btn-primary text-center text-white rounded-circle" style={{ fontSize: 22, width: 47, height: 47 }}>+</a>
                    </LoadsWithSpinner></div>
                <Collapse isOpen={showAddCustom}>
                    <div className="card-body">
                        <NewDestacadoForm />
                    </div>
                </Collapse>
            </div>
        </div>
    )
}