import { jsonFetchPromise } from './FetchHelper'
export default class TiposCampo {
    tiposCampoEstudio = []
    idTipoEstudio = ''

    constructor(idTipoEstudio) {
        this.idTipoEstudio = idTipoEstudio
    }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/tiposestudio/' + this.idTipoEstudio + '/camposestudio',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const tipos = json
                that.tiposCampoEstudio = tipos.map(({ idCampoEstudio, titulo, idTipoEstudio, unidad }) => { return { idCampoEstudio: idCampoEstudio, titulo: titulo, unidad: unidad } })
            },
            jwt)
    }
}