import React from 'react';

export default function LoadsWithSpinner(props){
    return (
        <>
        {props.variable ? <center>{props.text ? <span>{props.text}</span> : null}<img style={{margin:'0 auto'}}  height={props.height ? props.height : "100"} src="https://networking.report/images/01loading.gif"></img></center> :  <>
            {props.children}
        </>}
        </>
    )
}