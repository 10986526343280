import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export default function mySwallFetchErrorHandler(error,errorHandler){ 

    error.code == 401 ? errorHandler()
        : 
        MySwal.fire({
        icon: 'error',
        html: <p>{error.message}</p>,
        })
}