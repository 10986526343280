
import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useContext } from 'react'
import { Button, ButtonGroup, Radio } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Archivos from '../classes/Archivos'
import Archivo from '../classes/Archivo'
import AuthContext from '../AuthContext'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import LoadsWithSpinner from './LoadsWithSpinner'


export default function ArchivosModal(props) {
    const [loadingArchivos, setLoadingArchivos] = useState(false)
    const [loadingEnviando, setLoadingEnviando] = useState(false)
    const [archivos, setArchivos] = useState([])
    const [opcion, setOpcion] = useState('')
    const { JWT, logoutHandler } = useContext(AuthContext)

    const getArchivos = () => {
        setLoadingArchivos(true)
        const archivosRepo = new Archivos()
        archivosRepo.getAll(JWT)
            .then(() => {
                setArchivos(archivosRepo.archivos)
                setLoadingArchivos(false)
                console.log(archivos)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoadingArchivos(false)
            });
    }
    const enviarMail = () => {
        const confirm = window.confirm('Esta seguro que quiere enviar el archivo?')
        if (confirm) {
            setLoadingEnviando(true)
            const archivo = new Archivo(opcion)
            archivo.sendEmail(JWT, props.idPaciente)
                .then(() => {
                    setLoadingEnviando(false)
                    props.onHide()
                })
                .catch((error) => {
                    mySwallFetchErrorHandler(error, logoutHandler);
                    setLoadingEnviando(false)
                });
        }
    }

    useEffect(() => {
        getArchivos()
    }, [])
    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Enviar archivo por mail
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign:'center'}}>
                <LoadsWithSpinner variable={loadingArchivos} text="Cargando archivos...">
                    Seleccione un archivo
                    
                    <hr/>


                    {archivos.length > 0 && archivos.map((archivo, i) => <><Button color="info" style={{ width: "50%", marginBottom: "2%" }} variant="info" onClick={() => setOpcion(archivo.idArchivo())} active={opcion === archivo.idArchivo()}> {archivo.nombre()}</Button><br /></>)
                    }


                </LoadsWithSpinner>
            </Modal.Body>
            <Modal.Footer>
                <LoadsWithSpinner variable={loadingEnviando} text="Enviando archivo...">
                    <Button disabled={opcion == ""} color="primary" onClick={() => enviarMail()}>Enviar  <FontAwesomeIcon icon="paper-plane" /></Button>
                </LoadsWithSpinner>
            </Modal.Footer>
        </Modal>
    )
}

