import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')

export default class Comparador {
    #estudios = []

    constructor(estudios = []) {
        this.#estudios = estudios
        this.sortEstudios()
    }

    cantEstudios() {
        return this.#estudios.length
    }

    sortEstudios() {
        this.#estudios = this.#estudios.sort((a, b) => a.fecha().getTime() - b.fecha().getTime())
    }

    addEstudio(estudio) {
        this.#estudios.concat([estudio])
        this.sortEstudios()
    }

    tiposCampo() {
        return this.sinRepetidos(this.#estudios.map(estudio => estudio.tiposCampo()).flat())
    }

    tiposCampoComparables() {
        return this.tiposCampo()
            .filter(tipoCampo => this.#estudios.filter(estudio => estudio.admiteResultadoPara(tipoCampo)).length > 1)

    }

    sinRepetidos(array) {
        let sinRepes = []

        array.forEach(x => {
            if (!sinRepes.some(any => this.isEquivalent(any, x))) {
                sinRepes.push(x)
            }
        })

        return sinRepes
    }

    estudios() {
        return this.#estudios
    }

    estudiosParaTabla() {
        return this.#estudios.map(estudio => estudio.conCamposParaTabla(this.tiposCampo()))
    }


    cantidadEstudios() {
        return this.#estudios.length
    }

    fechasEstudios() {
        return this.#estudios.map(estudio => moment(estudio.fecha()))
    }

    fechasEntreEstudios() {
        let startDate = moment(this.#estudios[0].fecha());
        const endDate = moment(this.#estudios[this.#estudios.length - 1]);

        let result = [];

        if (endDate.isBefore(startDate)) {
            throw "End date must be greated than start date."
        }

        while (startDate.isBefore(endDate)) {
            result.push(startDate.format("YYYY-MM-01"));
            startDate.add(1, 'month');
        }

        return result
    }

    dataParaCharts(campoActual) {
        let tiposComparables = this.tiposCampoComparables()
        if (campoActual) {
            tiposComparables = tiposComparables.filter(x => this.isEquivalent(x, campoActual))
        }
        const result = tiposComparables.map(tipoCampo => {
            return {
                data: this.#estudios.filter(estudio => estudio.admiteResultadoPara(tipoCampo)).map(estudio => {
                    return { 
                        date: estudio.fecha().getTime(),
                        valor: parseInt(estudio.valorCampo(tipoCampo)),
                        name: tipoCampo.titulo
                    }
                }),
                tipoCampo: tipoCampo,
                esUnico: tiposComparables.length === 1
            }
        }
        )
        console.log(result)
        return result
    }

    isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }
}