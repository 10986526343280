import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'
import LoadsWithSpinner from './LoadsWithSpinner';
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Estudio from '../classes/Estudio';
import TiposEstudio from '../classes/TiposEstudio';
import {
    useHistory
} from "react-router-dom";

const MySwal = withReactContent(Swal)


export default function EstudioForm({ patient }) {
    const { JWT, logoutHandler } = useContext(AuthContext)
    let history = useHistory();
    const [tiposEstudio, setTiposEstudio] = useState([])
    const [loadingTipos, setLoadingTipos] = useState(true)
    const [loading, setLoading] = useState(false)

    const submitHandler = (values) => {
        setLoading(true)
        const newEstudio = new Estudio('', patient.idPaciente, values.fechaEstudio, values.tipoEstudio, values.descripcion, values.archivoEstudio)
        newEstudio.crear(JWT).then((respuesta) => {
            MySwal.fire({
                icon: 'success',
                text: "Estudio agregado correctamente!",
                confirmButtonText: 'Ok!'
            }).then((result) => {
                if (result.value) {
                    history.push("/pacientes/" + patient.idPaciente + "/estudios/" + respuesta.idEstudio + "/edit")
                }
            })
            setLoading(false)
        })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setLoading(false)
            })
    }

    const getTiposEstudio = () => {
        setLoadingTipos(true)
        const tiposEstudioRepo = new TiposEstudio()
        tiposEstudioRepo.getAll(JWT)
            .then(() => {
                setTiposEstudio(tiposEstudioRepo.tiposEstudio)
                setLoadingTipos(false)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoadingTipos(false)
            });
    }

    useEffect(() => {
        getTiposEstudio()
    }, [])

    return (
        <LoadsWithSpinner variable={loadingTipos} text="Cargando formulario">
            <div className={"col-sm-6 col-md-6 col-lg-6"}>
                <Formik
                    initialValues={{
                        descripcion: '',
                        fechaEstudio: new Date(),
                        tipoEstudio: '',
                        archivoEstudio: ''
                    }}
                    validationSchema={Yup.object({
                        descripcion: Yup.string()
                            .nullable(),
                        fechaEstudio: Yup.date()
                            .typeError('Solo se aceptan fechas')
                            .required('Requerido'),
                        tipoEstudio: Yup.number()
                            .required('Requerido'),
                        archivoEstudio: Yup.string()
                            .nullable()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        submitHandler(values)
                        setSubmitting(false)
                    }}
                >
                    <Form className="form">
                        <div className="pricingTable purple">
                            <div className="pricingTable-header">
                                <FormikSelect name="tipoEstudio" label="Tipo de estudio (*)" className="form-control">
                                    <option value="" label="Seleccionar" />
                                    {tiposEstudio.map(({ idTipoEstudio, titulo }) => <option value={idTipoEstudio} label={titulo} />)}
                                </FormikSelect>
                                <br />
                                <FormikDatePicker
                                    className="form-control"
                                    label="Fecha del estudio (*)"
                                    name="fechaEstudio"
                                />
                                <br />
                                <br />
                                <FormikTextInput
                                    name="descripcion"
                                    type="text"
                                    label="Descripción"
                                    placeholder=""
                                    className="form-control"
                                /></div>
                            <div className="pricingContent">
                                <FormikFileInput
                                    className="form-control"
                                    label="Adjuntar archivo"
                                    name="archivoEstudio"
                                    anyFile={true}
                                />
                            </div>
                            <br />
                            <LoadsWithSpinner variable={loading}>
                                <button className="btn btn-info" style={{ width: '100%' }} type="submit">Cargar</button>
                            </LoadsWithSpinner>
                        </div>
                    </Form>
                </Formik>
            </div>
        </LoadsWithSpinner>
    )
}
