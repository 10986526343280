import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../AuthContext'
import {
    useHistory
} from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EstudioForm from './EstudioForm';

const MySwal = withReactContent(Swal)

export default function EstudioNuevo({ patient }) {
    let history = useHistory();

    return (
        <div className="container-fluid">
            <div className="card shadow">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col">
                            <p className="text-primary m-0 font-weight-bold" style={{ fontSize: '25px' }}>Nuevo estudio <small style={{ color: '#bbbbbb' }}>(*) requerido</small></p>
                        </div>
                        <div className="col-lg-3">
                            <Link onClick={history.goBack}>
                                <h6 className="float-right">Cancelar y volver&nbsp;&nbsp;<button className="btn btn-primary text-center text-white rounded-circle" role="button" style={{ fontSize: '15px' }}><FontAwesomeIcon icon="undo" /></button></h6>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ padding: 20 }}>
                    <div className={"col-sm-3 col-md-3 col-lg-3"}></div>
                    <EstudioForm patient={patient}/>
                    <div className={"col-sm-3 col-md-3 col-lg-3"}></div>
                </div>
            </div>
        </div>
    )
}
