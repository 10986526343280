import React, { useState, useContext, useEffect, Suspense } from 'react'
import RichText from './RichTest'
import AuthContext from '../AuthContext'
import { jsonFetchPromise } from '../classes/FetchHelper'

import LoadsWithSpinner from './LoadsWithSpinner'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Link,
    useParams
} from "react-router-dom";
import { Consultation as ConsultationModel } from '../classes/Consultation'
import ToDoPlan from './ToDoPlan'

const MySwal = withReactContent(Swal)

export default function ConsultationResults(props) {

    const { JWT, logoutHandler } = useContext(AuthContext)

    const [consultation, setConsultation] = useState(props.consultation)
    const [loadingEnviando, setLoadingEnviando] = useState(false)

    const updateTreatment = (body) => {
        const consultationModel = consultation
        consultationModel.idConsulta = consultation.idConsulta
        consultationModel.tratamiento = body
        consultationModel.idPaciente = props.idPaciente

        return {
            update: consultationModel.updateTreatment(JWT), cb: () => {
                setConsultation(consultationModel)
            }
        }
    }

    const updateGoal = (body) => {
        const consultationModel = consultation
        consultationModel.idConsulta = consultation.idConsulta
        consultationModel.objetivo = body
        consultationModel.idPaciente = props.idPaciente

        return {
            update: consultationModel.updateGoal(JWT), cb: () => {
                setConsultation(consultationModel)
            }
        }
    }

    const sendResultsEmail = () => {

        if (!props.email) {
            MySwal.fire({
                icon: 'warning',
                text: "El paciente no tiene email registrado",
                confirmButtonText: 'Ok!'
            })
        } else {

            MySwal.fire({
                title: 'Va a enviar el resultado de la consulta por email',
                text: "¿Está seguro?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3fc3ee',
                cancelButtonColor: '#999999',
                confirmButtonText: 'Enviar'
            }).then((result) => {
                if (result.value) {
                    const promise = jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + props.idPaciente + '/consultas/' + consultation.idConsulta + '/sendemail', 'POST', {}, (json) => { }, JWT)
                    setLoadingEnviando(true)
                    promise
                        .then(() => {
                            setLoadingEnviando(false)
                            MySwal.fire({
                                icon: 'success',
                                text: "Resultados enviados correctamente!",
                                confirmButtonText: 'Ok!'
                            })
                        })
                        .catch((error) => {
                            mySwallFetchErrorHandler(error, logoutHandler);
                            setLoadingEnviando(false)
                        });

                }
            })
        }

    }

    useEffect(() => {
        props.updateConsultation(consultation)
    }, [consultation])

    return (
        <div className="card-body" style={{ display: props.display ? 'block' : 'none' }}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="row">
                        <RichText
                            enabled={false}
                            key={consultation.idConsulta}
                            titulo="Objetivo"
                            body={consultation.objetivo}
                            updateHandler={updateGoal}
                        />
                    </div>
                    <div className="row">
                        <RichText
                            enabled={false}
                            key={consultation.idConsulta}
                            titulo="Tratamiento"
                            body={consultation.tratamiento}
                            updateHandler={updateTreatment}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <ToDoPlan consultation={consultation} idPaciente={props.idPaciente} />
                </div>
                <LoadsWithSpinner variable={loadingEnviando} text="Enviando email">
                    <div className="col-lg-12">
                        <a className="btn btn-lg btn-info" style={{ width: '100%', color: 'white' }} onClick={() => sendResultsEmail()}>Enviar resultados por email</a>
                    </div>
                </LoadsWithSpinner>
            </div>
        </div>
    )
}