import React, { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useRouteMatch
} from "react-router-dom";

import PatientTable from './PatientTable'
import PatientForm from './PatientForm'
import PatientHC from './PatientHC'
import PatientGetter from './PatientGetter'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import EstudioTable from './EstudioTable';
import EstudioNuevo from './EstudioNuevo';
import EstudioEdit from './EstudioEdit';

const MySwal = withReactContent(Swal)

function PatientAdmin() {

    let { path } = useRouteMatch();

    return (

        <Switch>
            <Route exact path={path}>
                <PatientTable />
            </Route>
            <Route path={`${path}/new`}>
                <PatientForm />
            </Route>
            <Route path={`${path}/edit/:idPaciente`}>
                <PatientGetter>
                    <PatientForm />
                </PatientGetter>
            </Route>
            <Route path={`${path}/:idPaciente/estudios/:idEstudio/edit`}>
                <PatientGetter>
                    <EstudioEdit />
                </PatientGetter>
            </Route>
            <Route path={`${path}/:idPaciente/estudios/new`}>
                <PatientGetter>
                    <EstudioNuevo />
                </PatientGetter>
            </Route>
            <Route path={`${path}/:idPaciente/estudios`}>
                <PatientGetter>
                    <EstudioTable />
                </PatientGetter>
            </Route>
            <Route path={`${path}/:idPaciente`}>
                <PatientGetter>
                    <PatientHC />
                </PatientGetter>
            </Route>
        </Switch>

    )
}

export default PatientAdmin