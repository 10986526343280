import { jsonFetchPromise } from './FetchHelper'

export class Destacado {
    idPaciente = ''
    idDestacado = ''
    razon = ''
    fecha = ''
    titulo = ''
    tipoDestacado = ''
    destacados = []

    //Dependientes del tipo
    idConsulta = null

    constructor(idPaciente, idDestacado, razon, fecha, titulo, tipoDestacado, idConsulta = null) {
        this.idPaciente = idPaciente
        this.idDestacado = idDestacado
        this.razon = razon
        this.fecha = fecha
        this.titulo = titulo
        this.tipoDestacado = tipoDestacado
        this.idConsulta = idConsulta
    }

    getAll(jwt) {
        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this.idPaciente + '/destacados',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const obj = json
                this.destacados = obj.map((destacado) => new Destacado(
                    this.idPaciente,
                    destacado.idDestacado,
                    destacado.razon,
                    new Date(destacado.fecha),
                    destacado.titulo, destacado.tipoDestacado,
                    destacado.idConsulta ? destacado.idConsulta : null)
                )
            },
            jwt)
    }

    customJSON() {
        return JSON.stringify({
            razon: this.razon,
            titulo: this.titulo
        })
    }

    addCustom(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.idPaciente + '/customdestacados', 'POST', {}, (json) => { }, jwt, this.customJSON())
    }

    delete(jwt) {
        let endpoint = '';
        switch (this.tipoDestacado) {
            case 'item':
                endpoint = '/pacientes/' + this.idPaciente + '/itemsdestacados/' + this.idDestacado
                break;
            case 'custom':
                endpoint = '/pacientes/' + this.idPaciente + '/customdestacados/' + this.idDestacado
                break;
            case 'hito':
                endpoint = '/pacientes/' + this.idPaciente + '/hitosdestacados/' + this.idDestacado
                break;
            default:
                break;
        }
        return jsonFetchPromise('https://api.medicinafuncional.com.ar' + endpoint, 'DELETE', {}, (json) => { }, jwt)
    }
}
