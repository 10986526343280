import { jsonFetchPromise } from './FetchHelper'
import Archivo from './Archivo'

export default class Archivos {
    archivos = []


    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/archivos',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const archivos = json
                that.archivos = archivos
                    .map(({ idArchivo, nombre,descripcion , urlArchivo }) =>
                        new Archivo(idArchivo, nombre, descripcion, urlArchivo)
                    )
            },
            jwt)
    }
}
