import { jsonFetchPromise } from './FetchHelper'
export default class TiposEstudio {
    tiposEstudio = []

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/tiposestudio',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const tipos = json
                that.tiposEstudio = tipos.map(({ idTipoEstudio, titulo }) => { return { idTipoEstudio: idTipoEstudio, titulo: titulo } })
            },
            jwt)
    }
}