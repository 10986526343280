import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";

export function Header() {
  return (<Nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0 toggled">
    <div className="container-fluid d-flex flex-column p-0">
      <a className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0">
        <div className="sidebar-brand-icon "><i className="fas fa-laptop-medical"></i></div>
        <div className="sidebar-brand-text mx-3"><span>Dra. Kaufman</span></div>
      </a>
      <hr className="sidebar-divider my-0" />
      <ul className="nav navbar-nav text-light" id="accordionSidebar">
        <Link to="/pacientes">
          <li className="nav-item" role="presentation"><a className="nav-link active" >
            <i className="fas fa-user"></i><span>Pacientes</span></a>
          </li>
        </Link>
        <Link to="/archivos">
          <li className="nav-item" role="presentation"><a className="nav-link active" >
            <i className="fas fa-file"></i><span>Gestión de archivos</span></a>
          </li>
        </Link>
      </ul>
    </div>
  </Nav>);
}
