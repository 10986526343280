export default class ResultadoCampo {
    #idResultadoEstudio = ''
    #tipoCampo = ''
    #valor = ''
    #positivo = null

    constructor(idResultadoEstudio = '', valor = '', tipoCampo = '', positivo = null) {
        this.#tipoCampo = tipoCampo
        this.#valor = valor
        this.#positivo = positivo
        this.#idResultadoEstudio = idResultadoEstudio
    }

    positivo() { return this.#positivo }

    esDelCampo(tipoCampo) {
        return this.isEquivalent(this.#tipoCampo, tipoCampo)
    }


    valor() {
        return this.#valor
    }

    tipoCampo() {
        return this.#tipoCampo
    }

    nombreTipoCampo() {
        return this.#tipoCampo.titulo
    }

    unidad() { return this.#tipoCampo.unidad }

    isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }
}