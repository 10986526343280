import React, { useEffect, useState, useContext } from 'react'
import { useParams } from "react-router-dom";
import LoadsWithSpinner from './LoadsWithSpinner';
import Patient from '../classes/Patient';
import PatientForm from './PatientForm'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'


export default function PatientGetter(props) {

    const [loading, setLoading] = useState(false)
    const [patient, setPatient] = useState(null)
    const { JWT, logoutHandler } = useContext(AuthContext)

    let { idPaciente } = useParams()
    useEffect(() => {
        setLoading(true)
        const patient = new Patient()
        patient.idPaciente = idPaciente
        patient.get(JWT)
            .then(() => {
                setLoading(false)

                if (!patient.fechaNacimiento || patient.fechaNacimiento == '') {
                    patient.fechaNacimiento = ''
                } else {
                    const fechaNacimiento = new Date(patient.fechaNacimiento);
                    fechaNacimiento.setDate(fechaNacimiento.getDate() + 1)
                    patient.fechaNacimiento = fechaNacimiento
                }


                setPatient(patient)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setLoading(false)
            })
    }, [])


    return (   
        <LoadsWithSpinner variable={loading}>
            {patient && React.cloneElement(props.children,{ patient:patient})}
        </LoadsWithSpinner>
    )
}