import { jsonFetchPromise } from './FetchHelper'

export default class TiposItemConsulta {
    constructor() {
        this._tipos = []
    }

    set tipos(tipos) { this._tipos = tipos }
    get tipos() { return this._tipos }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/tipositemconsulta',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                that._tipos = json
            },
            jwt)
    }
}