export function jsonFetchPromise(url, method, headers, invokerResolve, jwt, body = null) {

    return new Promise((resolve, reject) => {
        let status = 200
        fetch(url, { method: method, headers: { ...headers, 'Authorization': jwt }, body: body })
            .then(function (response) {
                status = response.status
                if (!response.ok && status != 409) {
                    if (status == 401) {
                        throw { code: 401, message: 'Auth error' }
                    } else if (status >= 400) {
                        throw { code: status, message: 'Error de conexión, espere unos momentos o contacte con soporte' }
                    }
                }
                return response.json();
            }).then(function (json) {
                console.log(json)
                if (status == 409) {
                    throw { code: status, message: json.errorMsg }
                }
                invokerResolve(json)
                resolve(json)
            }).catch(function (error) {
                reject(error)
            })
    })
}

export function mockedJsonFetchPromise(url, method, headers, invokerResolve, jwt, body = null, result) {

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            invokerResolve(result)
            resolve(result)
        }, 2000)

    })
}



export default { jsonFetchPromise }