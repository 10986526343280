import { jsonFetchPromise } from './FetchHelper'
import ResultadoCampo from './ResultadoCampo'

export default class Estudio {

    #idEstudio = ''
    #idPaciente = ''
    #fecha = ''
    #tipoEstudio = ''
    #descripcion = ''
    #comentarios = ''
    #urlArchivo = ''
    #campos = []

    constructor(idEstudio = '', idPaciente = '', fecha = '', tipoEstudio = '', descripcion = '', urlArchivo = '', campos = [], comentarios = '') {
        this.#idEstudio = idEstudio
        this.#idPaciente = idPaciente
        this.#fecha = fecha
        this.#tipoEstudio = tipoEstudio
        this.#descripcion = descripcion
        this.#comentarios = comentarios
        this.#urlArchivo = urlArchivo
        this.#campos = campos
    }

    tabla() {

    }

    admiteResultadoPara(tipoCampo) {
        const campoEncontrado = this.#campos.find(campo => campo.esDelCampo(tipoCampo))
        return campoEncontrado && campoEncontrado.valor() != null && !isNaN(campoEncontrado.valor())
    }

    valorCampo(tipoCampo) {
        const resultadoCampo = this.#campos.find(campo => campo.esDelCampo(tipoCampo))

        if (!resultadoCampo) {
            throw new Error("No hay valor para ese campo")
        }

        return resultadoCampo.valor()
    }

    campos() {
        return this.#campos
    }

    tiposCampo() {
        return this.#campos.map(campo => campo.tipoCampo())
    }

    descripcion() { return this.#descripcion }
    comentarios() { return this.#comentarios }

    stringTipo() { return this.#tipoEstudio.titulo }
    stringFecha() { return (new Date(this.#fecha)).toLocaleDateString() }
    fecha() { return new Date(this.#fecha) }
    idEstudio() { return this.#idEstudio }
    tipoEstudio() { return this.#tipoEstudio }

    conCamposParaTabla(tiposCampo) {
        const camposParaTabla = tiposCampo.map(tipoCampo => {
            const campoCorrespondiente = this.#campos.find(campo => campo.esDelCampo(tipoCampo))
            return (campoCorrespondiente ? campoCorrespondiente : null)
        })
        return new Estudio(this.#idEstudio, this.#idPaciente, this.#fecha, this.#tipoEstudio, this.#descripcion, this.#urlArchivo, camposParaTabla, this.#comentarios)
    }

    linkArchivo() {
        if (!this.#urlArchivo) {
            return ''
        }
        return "https://api.medicinafuncional.com.ar/uploads/" + this.#urlArchivo
    }


    createFormData(edit = false) {
        const formData = new FormData();

        let fecha = this.#fecha
        if (fecha != '') {
            fecha = new Date(this.#fecha)
            fecha.setHours(0, 0, 0)
            fecha = fecha.toISOString()
        }

        formData.append('fecha', fecha)
        if (!edit) { formData.append('idTipoEstudio', this.#tipoEstudio) }
        formData.append('descripcion', this.#descripcion)
        formData.append('archivoPaciente', this.#urlArchivo)
        formData.append('comentarios', this.#comentarios)

        return formData
    }

    crear(jwt) {
        const formData = this.createFormData()
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios', 'POST', {}, (json) => { }, jwt, formData)
    }

    editar(jwt) {
        const formData = this.createFormData(true)
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios/' + this.#idEstudio, 'PUT', {}, (json) => { }, jwt, formData)
    }

    get(jwt) {
        
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios/' + this.#idEstudio, 'GET', {},
            ({ fecha, idTipoEstudio, descripcion, urlArchivo, tituloTipo, comentarios }) => {
                this.#fecha = new Date(fecha)
                this.#tipoEstudio = { idTipoEstudio: idTipoEstudio, titulo: tituloTipo }
                this.#descripcion = descripcion
                this.#urlArchivo = urlArchivo
                this.#comentarios = comentarios
                console.log(comentarios)
            }, jwt)
    }

    delete(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios/' + this.#idEstudio, 'DELETE', {}, (json) => { }, jwt)
    }

    getCampos(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios/' + this.#idEstudio + "/resultadosestudio", 'GET', {},
            (resultados) => {
                this.#campos = resultados.map(({ idResultadoEstudio, idCampoEstudio, valor, positivo, idTipoEstudio, titulo, unidad }) => new ResultadoCampo(
                    idResultadoEstudio,
                    valor,
                    {
                        idCampoEstudio: idCampoEstudio,
                        titulo: titulo,
                        idTipoEstudio: idTipoEstudio,
                        unidad: unidad
                    },
                    positivo
                ))
            }, jwt)
    }

    updateResultados(jwt, resultados) {
        console.log(resultados)
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios/' + this.#idEstudio + '/resultadosestudio', 'PUT', {}, (json) => { }, jwt,
            JSON.stringify(resultados.map(({ idCampoEstudio, valor, positivo }) => { return { idCampoEstudio: idCampoEstudio, valor: (valor ? valor : null), positivo: (positivo != '0' && positivo != '1') ? null : positivo } })))
    }

}