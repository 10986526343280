import React from 'react';
import PatientAdmin from './components/PatientAdmin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

export function Body(props) {

  let { path, url } = useRouteMatch();

  return (
    <div id="content">
      <Nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
        <div className="container-fluid"><button className="btn btn-link d-md-none rounded-circle mr-3" id="sidebarToggleTop" type="button"><i className="fas fa-bars"></i></button>
          <ul className="nav navbar-nav flex-nowrap ml-auto">
            <div className="d-none d-sm-block topbar-divider"></div>
            <li className="nav-item dropdown no-arrow" role="presentation">
              <div className="nav-item dropdown no-arrow"><a className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" style={{cursor: 'pointer'}}><span className="d-none d-lg-inline mr-2 text-gray-600 small">Dra. Carolina Kaufman</span><FontAwesomeIcon icon="user" /></a>
                <div className="dropdown-menu shadow dropdown-menu-right animated--grow-in" role="menu">
                 <a className="dropdown-item" role="presentation" onClick={props.logoutHandler} style={{cursor: 'pointer'}}><i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Cerrar sesión</a></div>
              </div>
            </li>
          </ul>
        </div>
      </Nav>
      <PatientAdmin />
    </div>);
}
