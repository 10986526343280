import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { TimeLine as TimeLineModel } from '../classes/TimeLine'
import TimeLineItem from '../classes/TimeLineItem'
import './TimeLine.css'
import LoadsWithSpinner from './LoadsWithSpinner';
import { FormikYearPicker, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
import { animateScroll as scroll, scroller } from 'react-scroll'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AuthContext from '../AuthContext'
import { UncontrolledPopover, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import TiposHito from '../classes/TiposHito'

const MySwal = withReactContent(Swal)

export const TimeLine = (props) => {

	const [timeLineItems, setTimeLineItems] = useState([])
	const [loading, setLoading] = useState(true)

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const { JWT, logoutHandler } = useContext(AuthContext);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const togglePopOver = () => setPopoverOpen(!popoverOpen);
	const [tiposHito, setTiposHito] = useState([])


	function getTipos() {
		const tiposHitoModel = new TiposHito();
		tiposHitoModel.getAll(JWT)
			.then(() => {
				setTiposHito(tiposHitoModel.tiposHito)
			})
			.catch((error) => {
				mySwallFetchErrorHandler(error, logoutHandler);

			});
	}

	const handleNuevoHito = (item) => {
		item.loading(true)
		const newItem = new TimeLineItem()
		newItem.idPaciente = item.idPaciente
		newItem.add(JWT, item)
			.then((respuesta) => {
				const tipoHito = tiposHito.find(tipo => tipo.idTipoHito == item.idTipoHito)
				console.log(tipoHito)
				const newItems = timeLineItems.concat([{
					idPaciente: item.idPaciente,
					titulo: item.titulo,
					fecha: item.fecha,
					detalle: item.detalle,
					tipo: tipoHito.nombre,
					color: tipoHito.color,
					idHito: respuesta.idHito,
					estaDestacado: '0'
				}])
				setTimeLineItems(newItems.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
				item.loading(false)
				togglePopOver()
			})
			.catch((error) => {
				mySwallFetchErrorHandler(error, logoutHandler)
				item.loading(false)
			})
	}
	const handleUpdateHito = (item) => {
		item.loading(true)
		//setLoadingAdded(true)
		const newItem = new TimeLineItem()
		newItem.idPaciente = item.idPaciente
		newItem.idHito = item.idHito
		newItem.titulo = item.titulo
		newItem.detalle = item.detalle
		newItem.fecha = item.fecha
		newItem.update(JWT, item.idTipoHito)
			.then((respuesta) => {
				const tipoHito = tiposHito.find(tipo => tipo.idTipoHito === item.idTipoHito)
				const newItems = timeLineItems.map((hito) => {
					if (hito.idHito === item.idHito) {
						hito.titulo = item.titulo;
						hito.fecha = item.fecha;
						hito.detalle = item.detalle;
						hito.tipo = tipoHito.nombre;
						hito.color = tipoHito.color;
						hito.estaDestacado = '0';
					}
					return hito;
				})
				setTimeLineItems(newItems.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
				item.loading(false)
				togglePopOver()
			})
			.catch((error) => {
				mySwallFetchErrorHandler(error, logoutHandler)
				item.loading(false)
			})
	}

	const deleteTimeLineItem = (idHito, titulo, setDeleting) => {
		MySwal.fire({
			title: 'Va a borrar el hito ' + (titulo ?? ""),
			text: "¿Está seguro?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Borrar'
		}).then((result) => {
			if (result.value) {
				setDeleting(true);
				const hito = new TimeLineItem()
				hito.idPaciente = props.idPaciente
				hito._idHito = idHito
				hito.delete(JWT)
					.then((rta) => {
						setTimeLineItems(timeLineItems.filter(hito => hito.idHito !== idHito))
						setDeleting(false)
					})
					.catch((error) => {
						mySwallFetchErrorHandler(error, logoutHandler)
						setDeleting(false)
					})
			}
		})
	}

	const destacarTimeLineItem = (idHito, setLoadingDestacar) => {
		setLoadingDestacar(true)
		const hito = new TimeLineItem()
		hito._idPaciente = props.idPaciente
		hito._idHito = idHito
		hito.destacar(JWT)
			.then((rta) => {
				setTimeLineItems(timeLineItems.map(hito => {
					if (hito.idHito === idHito) {
						hito.estaDestacado = '1'
					}
					return hito
				}))
				setLoadingDestacar(false)
				props.refreshDestacados()
			})
			.catch((error) => {
				mySwallFetchErrorHandler(error, logoutHandler)
				setLoadingDestacar(false)

			})
	}
	const deleteDestacado = (idHito, setLoadingDestacar) => {
		setLoadingDestacar(true)
		const hito = new TimeLineItem()
		hito._idPaciente = props.idPaciente
		hito._idHito = idHito
		hito.deleteDestacado(JWT)
			.then((rta) => {
				setTimeLineItems(timeLineItems.map(hito => {
					if (hito.idHito === idHito) {
						hito.estaDestacado = '0'
					}
					return hito
				}))
				setLoadingDestacar(false)
				props.refreshDestacados()
			})
			.catch((error) => {
				mySwallFetchErrorHandler(error, logoutHandler)
				setLoadingDestacar(false)

			})
	}
	function scrollPlus() {
		scroller.scrollTo('addNew', {
			duration: 1000,
			delay: 100,
			smooth: true,
			containerId: 'timeLineCard'
			//offset: -100
		});
	}
	const FormNewTimeLineItem = (item) => {
		const [loadingEditing, setLoadingEditing] = useState(false)
		return (
			<Formik
				initialValues={{
					titulo: item.titulo,
					detalle: item.detalle,
					idTipoHito: item.idTipoHito ?? 6,
					fecha: item.fecha || new Date(),
					idPaciente: props.idPaciente,
					idHito: item.idHito
				}}
				validationSchema={Yup.object({
					titulo: Yup.string().nullable(),
					detalle: Yup.string().nullable(),
					idTipoHito: Yup.number()
						.required('Seleccione algun tipo'),
					fecha: Yup.date()
						.typeError('Solo se aceptan Fechas')
						.required('Seleccione una fecha')
				})}
				onSubmit={(values, { setSubmitting }) => {
					item.idHito ? handleUpdateHito({ ...values, loading: setLoadingEditing }) : handleNuevoHito({ ...values, loading: setLoadingEditing })
					setSubmitting(false)
				}}
			>
				<Form className="form" style={{ padding: 20 }}>
					<FormikSelect label="Tipo (*)" name="idTipoHito" className="form-control">
						{tiposHito.map(tipo => <option key={tipo.idTipoHito} value={tipo.idTipoHito}>{tipo.nombre}</option>)}
					</FormikSelect>
					<br />
					<FormikYearPicker
						className="form-control"
						label="Año (*)"
						name="fecha"
					/>
					<br />
					<br />
					<FormikTextInput
						label="Titulo"
						name="titulo"
						type="text"
						placeholder=""
						className="form-control"
					/>
					<br />
					<FormikTextArea
						label="Detalle"
						name="detalle"
						type="text"
						placeholder=""
						className="form-control"
					/>
					<br />

					<LoadsWithSpinner variable={loadingEditing}>
						<button className="btn btn-info btn-lg" type="submit">{item.idHito ? 'Guardar' : 'Agregar'}</button>
					</LoadsWithSpinner>
				</Form>
			</Formik>)
	}
	const TimeLineElement = ({ fecha, titulo, detalle, tipo, color, left, idHito, estaDestacado }) => {
		const [loadingDelete, setLoading] = useState(false)

		return (<article className={"timeline-entry " + (left ? 'left-aligned' : '')}>
			<div className="timeline-entry-inner">

				<time className="timeline-time" datetime={fecha}><span>{moment(fecha).year()}</span> <span>{moment().diff(moment(fecha), 'years') <= 1 ? 'Este año' : moment(fecha).fromNow()}</span></time>
				<div className={"timeline-icon bg-" + color}>
					<i className="entypo-feather"></i>
				</div>
				<div className="timeline-label">
					{left ?
						<>
							<LoadsWithSpinner variable={loadingDelete} height={25} >
								<FontAwesomeIcon onClick={() => deleteTimeLineItem(idHito, titulo, setLoading)} className="float-left mr-2" icon="trash" />
								<FontAwesomeIcon id={'edit' + idHito} className="float-left mr-1" icon="edit" />
								<FontAwesomeIcon onClick={() => estaDestacado === '0' ? destacarTimeLineItem(idHito, setLoading) : deleteDestacado(idHito, setLoading)} style={estaDestacado === '1' ? { color: "gold" } : {}} className={"float-left"} icon="star" />
							</LoadsWithSpinner>
							<UncontrolledPopover trigger="legacy" placement="right" target={"edit" + idHito}>
								<PopoverHeader>Editando Hito</PopoverHeader>
								<PopoverBody>
									<FormNewTimeLineItem idHito={idHito} titulo={titulo} detalle={detalle} fecha={new Date(fecha)} idTipoHito={tiposHito.find((tipoH) => tipoH.nombre === tipo).idTipoHito} />
								</PopoverBody>
							</UncontrolledPopover>
						</> :
						<>
							<LoadsWithSpinner variable={loadingDelete} height={25} >
								<FontAwesomeIcon onClick={() => deleteTimeLineItem(idHito, titulo, setLoading)} className="float-right ml-2" icon="trash" />
								<FontAwesomeIcon id={'edit' + idHito} className="float-right " icon="edit" />
								<FontAwesomeIcon onClick={() => estaDestacado === '0' ? destacarTimeLineItem(idHito, setLoading) : deleteDestacado(idHito, setLoading)} style={estaDestacado === '1' ? { color: "gold" } : {}} className="float-right mr-2" icon="star" />
							</LoadsWithSpinner>
							<UncontrolledPopover trigger="legacy" placement="right" target={"edit" + idHito}>
								<PopoverHeader>Editando Hito</PopoverHeader>
								<PopoverBody>
									<FormNewTimeLineItem idHito={idHito} titulo={titulo} detalle={detalle} fecha={new Date(fecha)} idTipoHito={tiposHito.find((tipoH) => tipoH.nombre === tipo).idTipoHito} />
								</PopoverBody>
							</UncontrolledPopover>
						</>
					}
					<h2>{titulo ? titulo : tipo} <br /><span>{titulo && tipo}</span></h2>
					<p>{detalle}</p>
				</div>
			</div>

		</article>)
	}

	useEffect(() => {
		getTipos()
		const timelineModel = new TimeLineModel();
		timelineModel.idPaciente = props.idPaciente
		setLoading(true);
		timelineModel.getAll(JWT)
			.then((respuesta) => {
				setLoading(false);
				setTimeLineItems(timelineModel.timeLineItems);
				scrollPlus()
			})
			.catch((error) => {
				mySwallFetchErrorHandler(error, logoutHandler)
				setLoading(false);
			})
	}, [])
	return (
		<div className="card shadow mb-4">
			<div className="card-header py-3" onClick={toggle} style={{ cursor: 'pointer' }}>
				<h6 className="text-primary m-0 font-weight-bold"><FontAwesomeIcon icon="calendar" /> Línea de tiempo</h6>
			</div>
			<Collapse isOpen={isOpen}>
				<Card>
					<CardBody id="timeLineCard" className="card-body" style={{ height: 500, overflowY: 'scroll' }}>
						<div className="timeline-centered">

							<article className="timeline-entry begin">
								<div className="timeline-entry-inner">
									<Button className="timeline-icon" id="popOverNew">
										<FontAwesomeIcon name="addNew" icon="plus" style={{ 'margin-bottom': '50%' }} />
									</Button>
									<UncontrolledPopover trigger="legacy" placement="right" target="popOverNew">
										<PopoverHeader>Nuevo Hito</PopoverHeader>
										<PopoverBody>
											<FormNewTimeLineItem />
										</PopoverBody>
									</UncontrolledPopover>

								</div>
							</article>
							<LoadsWithSpinner variable={loading} texto="Cargando Linea de Tiempo">
								{
									tiposHito.length > 0 && timeLineItems.map((item, i) => <TimeLineElement titulo={item.titulo}
										fecha={item.fecha}
										detalle={item.detalle}
										tipo={item.tipo}
										left={i % 2 === 0 ? true : false}
										color={item.color}
										estaDestacado={item.estaDestacado}
										idHito={item.idHito}
									/>)
								}
							</LoadsWithSpinner>
						</div>
					</CardBody>
				</Card>
			</Collapse>
		</div>
	)
}