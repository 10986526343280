import React, { useState, useEffect, useContext } from "react";
import Consultation from "./Consultation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlanProvider } from "./PlanContext";
import Consultations from "./Consultations";
import ToDoPlan from "./ToDoPlan";
import { Link } from "react-router-dom";
import AuthContext from "../AuthContext";
import { Destacados } from "./Destacados";
import { TimeLine } from "./TimeLine";
import ArchivosModal from "./ArchivosModal";
import { Button } from "reactstrap";
import "./Searchbar.css";
import ResultadoBusqueda from "./ResultadoBusqueda";
import LoadsWithSpinner from "./LoadsWithSpinner";
import { jsonFetchPromise } from "../classes/FetchHelper";
import mySwallFetchErrorHandler from "./mySwallFetchErrorHandler";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";

const MySwal = withReactContent(Swal);

export default function PatientHC(props) {
  const { JWT, logoutHandler } = useContext(AuthContext);

  const [patient, setPatient] = useState(props.patient);
  const [showGlobalPlan, setShowGlobalPlan] = useState(true);
  const [showDestacados, setShowDestacados] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [buscando, setBuscando] = useState(false);
  const [busqueda, setBusqueda] = useState([]);

  const buscar = ({ target }) => {
    setTextoBusqueda(target.value);
  };

  useEffect(() => {
    const textoBusquedaAnterior = textoBusqueda;
    if (textoBusqueda.length > 0) {
      const promise = jsonFetchPromise(
        "https://api.medicinafuncional.com.ar/pacientes/" +
          patient.idPaciente +
          "/search?value=" +
          textoBusqueda,
        "GET",
        {},
        (json) => {},
        JWT
      );
      setBuscando(true);
      promise
        .then((response) => {
          setBuscando(false);
          if (textoBusquedaAnterior === textoBusqueda) {
            setBusqueda(response);
          }
        })
        .catch((error) => {
          mySwallFetchErrorHandler(error, logoutHandler);
          setBuscando(false);
        });
    } else {
      busqueda.length && setBusqueda([]);
    }
  }, [textoBusqueda]);

  const reRenderGlobalPlan = () => {
    setShowGlobalPlan(false);
    setTimeout(() => setShowGlobalPlan(true), 1);
  };

  const reRenderDestacados = () => {
    setShowDestacados(false);
    setTimeout(() => setShowDestacados(true), 1);
  };

  return (
    <PlanProvider value={reRenderGlobalPlan}>
      <div className="container-fluid">
        <div className="d-sm-flex justify-content-between align-items-center mb-4">
          <h3 className="text-dark mb-0">
            Paciente: {patient.nombre + " " + patient.apellido}
          </h3>
          {false && (
            <a
              className="btn btn-primary btn-sm d-none d-sm-inline-block"
              role="button"
            >
              <i className="fas fa-download fa-sm text-white-50"></i>
              &nbsp;Descargar H.C.
            </a>
          )}
          <div
            className="form-group has-search d-sm-flex "
            style={{ width: "30%", margin: 0 }}
          >
            <span className="fa fa-search form-control-feedback"></span>
            <input
              name="buscador"
              id="buscador"
              type="text"
              className="form-control"
              placeholder="Buscar"
              value={textoBusqueda}
              onChange={buscar}
            />
          </div>
          <Link
            to="/pacientes"
            className="btn btn-primary btn-sm d-none d-sm-inline-block"
          >
            Volver a Gestión de Pacientes
          </Link>
        </div>
        {!textoBusqueda.length && !buscando ? (
          <div className="row">
            <div className="col-lg-3">
              <div className="row">
                <div className="col">
                  <div className="card mb-3">
                    <div className="card-body text-center shadow">
                      <Link
                        to={"/pacientes/edit/" + patient.idPaciente}
                        className="btn btn-default btn-m border"
                        style={{
                          top: 0,
                          right: 0,
                          float: "right",
                          position: "absolute",
                          margin: 10,
                        }}
                      >
                        <FontAwesomeIcon icon="edit" />
                      </Link>
                      <img
                        className="rounded-circle mb-3 mt-4"
                        src={
                          "https://api.medicinafuncional.com.ar/uploads/" +
                          patient.urlImagen
                        }
                        width="160"
                        height="160"
                      />
                      <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col">
                          <h6 style={{ color: "rgb(173,175,186)", margin: 0 }}>
                            Nombre
                          </h6>
                          <h6 style={{ margin: 0 }}>
                            {patient.nombre + " " + patient.apellido}
                          </h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col">
                          <h6 style={{ color: "rgb(173,175,186)", margin: 0 }}>
                            Fecha nacimiento
                          </h6>
                          <h6 style={{ margin: 0 }}>
                            {patient.fechaNacimiento
                              ? patient.fechaNacimiento.toLocaleDateString() +
                                " (" +
                                moment().diff(
                                  patient.fechaNacimiento,
                                  "years",
                                  false
                                ) +
                                " años)"
                              : ""}
                          </h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col">
                          <h6 style={{ color: "rgb(173,175,186)", margin: 0 }}>
                            CUIT/CUIL
                          </h6>
                          <h6 style={{ margin: 0 }}>{patient.cuit}</h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col">
                          <h6 style={{ color: "rgb(173,175,186)", margin: 0 }}>
                            Credencial
                          </h6>
                          <h6 style={{ margin: 0 }}>
                            {patient.numeroAsociado
                              ? patient.numeroAsociado +
                                " (" +
                                patient.nombrePrepaga +
                                ")"
                              : "-"}
                          </h6>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 10 }}>
                        <div className="col">
                          <h6 style={{ color: "rgb(173,175,186)", margin: 0 }}>
                            Mail
                          </h6>
                          <h6 style={{ margin: 0 }}>{patient.mail}</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="shadow card">
                            <a
                              className="btn btn-link text-left card-header font-weight-bold"
                              data-toggle="collapse"
                              aria-expanded="true"
                              aria-controls="collapse-5"
                              href="#collapse-5"
                              role="button"
                              style={{
                                fontSize: 13,
                                color: "rgb(133,135,150)",
                              }}
                            >
                              Descripción general
                            </a>
                            <div className="collapse show" id="collapse-5">
                              <div className="card-body">
                                <p className="m-0">
                                  {patient.descripcionGeneral}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Link
                    to={"/pacientes/" + patient.idPaciente + "/estudios"}
                    className="btn btn-primary btn-m border"
                    style={{ width: "100%" }}
                  >
                    <FontAwesomeIcon icon="file" /> Gestión de estudios
                  </Link>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <button
                    disabled={!patient.mail}
                    className="btn btn-primary btn-m border"
                    style={{ width: "100%" }}
                    onClick={() => setModalShow(true)}
                  >
                    <FontAwesomeIcon icon="paper-plane" /> Enviar archivo
                  </button>

                  <ArchivosModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    idPaciente={patient.idPaciente}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  {showGlobalPlan && (
                    <ToDoPlan global={true} idPaciente={patient.idPaciente} />
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              {
                <div className="row">
                  <div className="col">
                    <TimeLine
                      idPaciente={patient.idPaciente}
                      fechaNacimiento={patient.fechaNacimiento}
                      refreshDestacados={reRenderDestacados}
                    />
                  </div>
                </div>
              }
              <div className="row">
                <div className="col">
                  {showDestacados && (
                    <Destacados idPaciente={patient.idPaciente} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Consultations
                    idPaciente={patient.idPaciente}
                    email={patient.mail}
                    refreshDestacados={reRenderDestacados}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="container-fluid"
            style={{
              display: textoBusqueda.length || buscando ? "block" : "none",
            }}
          >
            <div className="card shadow">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col">
                    <p
                      className="text-primary m-0 font-weight-bold"
                      style={{ fontSize: 20 }}
                    >
                      {"Resultados de busqueda '" + textoBusqueda + "'"}
                    </p>
                  </div>
                  <Link onClick={() => setTextoBusqueda("")}>
                    <h6 className="float-right" style={{ marginRight: 20 }}>
                      Cerrar&nbsp;&nbsp;
                      <button
                        className="btn btn-primary text-center text-white rounded-circle"
                        role="button"
                        style={{ fontSize: "15px" }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </button>
                    </h6>
                  </Link>
                </div>
              </div>
              <LoadsWithSpinner variable={buscando}>
                {busqueda.map((resultado, index) => (
                  <ResultadoBusqueda key={index} {...resultado} />
                ))}
              </LoadsWithSpinner>
            </div>
          </div>
        )}
      </div>
    </PlanProvider>
  );
}
