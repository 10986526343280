import { jsonFetchPromise } from './FetchHelper'
import Estudio from './Estudio'

export default class Estudios {
    estudios = []
    #idPaciente = ''

    constructor(idPaciente) {
        this.#idPaciente = idPaciente
    }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this.#idPaciente + '/estudios',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const estudios = json
                that.estudios = estudios
                    .map(({ idEstudio, idPaciente, fecha, idTipoEstudio, tituloTipo, descripcion, urlArchivo, comentarios }) =>
                        new Estudio(idEstudio, idPaciente, new Date(fecha), { idTipoEstudio: idTipoEstudio, titulo: tituloTipo }, descripcion, urlArchivo, [] ,comentarios)
                    )
            },
            jwt)
    }
}
