import { jsonFetchPromise } from './FetchHelper'

export class Plan {
    constructor() {
        this._idPaciente = ''
        this._idConsultation = ''
        this._items = []
    }

    set items(items) { this._items = items }
    get items() { return this._items }
    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    get idPaciente() { return this._idPaciente }
    set idConsultation(idConsultation) { this._idConsultation = idConsultation }
    get idConsultation() { return this._idConsultation }

    /*
    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const obj = json
                that._items = obj.map((consultation) => Object.assign(new Consultation(), consultation))
            },
            jwt)
    }*/

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/itemsplan',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                console.log(json)
                const obj = json
                that._items = obj
            },
            jwt)
    }

    getAllFromConsultation(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsultation + '/itemsplan',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                console.log(json)
                const obj = json
                that._items = obj
            },
            jwt)
    }

    addItem(jwt, item) {
        let newItem = {}
        newItem.isCompleted = item.isCompleted
        newItem.titulo = item.titulo
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsultation + '/itemsplan', 'POST', {}, (json) => { }, jwt, JSON.stringify(newItem))
    }

    changeStatus(jwt, id, newStatus) {
        let newItem = {}
        newItem.isCompleted = newStatus
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsultation + '/itemsplan/' + id, 'PUT', {}, (json) => { }, jwt, JSON.stringify(newItem))
    }

    deleteItem(jwt, id) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + this._idPaciente + '/consultas/' + this._idConsultation + '/itemsplan/' + id, 'DELETE', {}, (json) => { }, jwt)
    }
}