import { jsonFetchPromise } from './FetchHelper'

export default class TiposHito {
    constructor() {
        this._tiposHito = []
    }

    set tiposHito(tiposHito) { this._tiposHito = tiposHito }
    get tiposHito() { return this._tiposHito }

    getAll(jwt) {
        var that = this
        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/tiposhito',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                that._tiposHito = json
            },
            jwt)
    }
}