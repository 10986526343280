import { jsonFetchPromise } from './FetchHelper'

export default class TimeLineItem {
    constructor() {
        this._titulo = ''
        this._detalle =''
        this._tipo =''
        this._idPaciente =''
        this._color=''
        this._fecha =''
        this._idHito=''
        this._estaDestacado=false
    }

    set titulo(titulo) { this._titulo = titulo }
    get titulo() { return this._titulo }

    set detalle(detalle) { this._detalle = detalle }
    get detalle() { return this._detalle }

    set tipo(tipo) { this._tipo = tipo }
    get tipo() { return this._tipo }

    set color(color) { this._color = color }
    get color() { return this._color }

    set estaDestacado(estaDestacado) { this._estaDestacado = estaDestacado }
    get estaDestacado() { return this._estaDestacado }

    set fecha(fecha) { this._fecha = fecha }
    get fecha() { return this._fecha }
   
    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    get idPaciente() { return this._idPaciente }
    set idHito(idHito) { this._idHito = idHito }
    get idHito() { return this._idHito }

    add(jwt,item) {
        const jsonItem={
            idPaciente:item.idPaciente,
            titulo:item.titulo,
            fecha:item.fecha,
            detalle:item.detalle,
            idTipoHito:item.idTipoHito
        }
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/timeline', 'POST', {}, (json) => { }, jwt, JSON.stringify(jsonItem))
    }

    update(jwt,idTipoHito) {
        const jsonItem={
            titulo:this._titulo,
            fecha:this._fecha,
            detalle:this._detalle,
            idTipoHito:idTipoHito
        }
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/timeline/'+this._idHito, 'PUT', {}, (json) => { }, jwt, JSON.stringify(jsonItem))
    }

    destacar(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/timeline/'+this._idHito+'/destacar', 'POST', {}, (json) => { }, jwt)
    }
    deleteDestacado(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/timeline/'+this._idHito+'/destacar', 'DELETE', {}, (json) => { }, jwt)
    }

    delete(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/timeline/'+this._idHito, 'DELETE', {}, (json) => { }, jwt)
    }
}