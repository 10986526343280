import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

const tiposString = {
  itemConsulta: "Item consulta",
  estudio: "Estudio",
  hito: "Línea de tiempo",
};

const tiposColor = {
  itemConsulta: "info",
  estudio: "warning",
  hito: "danger",
};

export default function ResultadoBusqueda({
  param,
  tipoResultado,
  titulo,
  detalle,
}) {
  let { url } = useRouteMatch();

  const urlFromType = (type, id) => {
    switch (type) {
      case "itemConsulta":
        return `${url}/consultas/` + id.split(',')[0];

      case "estudio":
        return `${url}/estudios/`;

      default:
        break;
    }
  };

  return (
    <div
      className={
        "card shadow border-left-" + tiposColor[tipoResultado] + " py-2"
      }
      style={{ margin: 10 }}
    >
      <div className="card-body" style={{ padding: 10 }}>
        <div className="row align-items-center no-gutters">
          <div className="col mr-2">
            <Link
              to={urlFromType(tipoResultado, param)}
              style={{ textDecoration: "none", color: '#666666' }}
            >
              <div className="text-uppercase text-success font-weight-bold text-xs mb-1">
                <span style={{ color: "rgb(179,179,179)" }}>
                  {tiposString[tipoResultado]}
                </span>
              </div>
              <div className="text-dark font-weight-bold h5 mb-0">
                <span>{titulo}</span>
              </div>
              {tipoResultado === "itemConsulta" ? (
                <div dangerouslySetInnerHTML={{ __html: detalle }}></div>
              ) : (
                <div className="font-weight-bold mb-1">
                  <span style={{ color: "rgb(179,179,179)" }}>{detalle}</span>
                </div>
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
