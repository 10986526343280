import { jsonFetchPromise } from './FetchHelper'

export default class Prepagas {
    constructor() {
        this._prepagas = []
    }

    set prepagas(prepagas) { this._prepagas = prepagas }
    get prepagas() { return this._prepagas }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/prepagas',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                that._prepagas = json
            },
            jwt)
    }
}