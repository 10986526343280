import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'
import LoadsWithSpinner from './LoadsWithSpinner';
import Modal from 'react-bootstrap/Modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ArchivosModal(props) {


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Crear campo personalizado
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                <Formik
                    initialValues={{
                        titulo: '',
                        unidad: '',
                    }}
                    validationSchema={Yup.object({
                        titulo: Yup.string()
                            .required('Requerido'),
                        unidad: Yup.string()
                            .required('Requerido'),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        props.submitHandler(values)
                        setSubmitting(false)
                    }}
                >
                    <Form className="form" style={{ width: '50%', margin: '0 auto' }}>
                        <FormikTextInput
                            name="titulo"
                            type="text"
                            label="Nombre del campo (*)"
                            placeholder=""
                            className="form-control"
                        />
                        <br/>
                        <FormikTextInput
                            name="unidad"
                            type="text"
                            label="Unidad (*)"
                            placeholder=""
                            className="form-control"
                        />
                        <br/>
                        <button className="btn btn-info" style={{ width: '100%' }} type="submit">Crear</button>
                    </Form>
                </Formik>
            </Modal.Body>
            <Modal.Footer>
             
            </Modal.Footer>
        </Modal>
    )
}

