import React, { useState, useEffect, useContext } from 'react'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Plan } from '../classes/Plan'
import PlanItem from './PlanItem'
import AuthContext from '../AuthContext'
import LoadsWithSpinner from './LoadsWithSpinner'

import PlanContext from './PlanContext'

const MySwal = withReactContent(Swal)

export default React.memo(function ToDoPlan(props) {
    const { JWT, logoutHandler } = useContext(AuthContext)

    const updateGlobal = useContext(PlanContext)

    const consultation = props.consultation
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [newItemText, setNewItemText] = useState("")

    const handleStatusChange = (idItemPlan, idConsulta, newStatus) => {
        const plan = new Plan();
        plan.idPaciente = props.idPaciente
        plan.idConsultation = idConsulta || consultation.idConsulta
        setItems(items.map(x=>{
            if(x.idItemplan==idItemPlan){
                    return Object.assign(x,{isCompleted:newStatus})
                }
                return x
        }))

        plan.changeStatus(JWT, idItemPlan, newStatus)
            .then(() => {
                //refreshItems()  
             
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
            });
    }


    const handleDelete = (idItemPlan) => {
        const plan = new Plan();
        plan.idPaciente = props.idPaciente
        plan.idConsultation = consultation.idConsulta

        plan.deleteItem(JWT, idItemPlan)
            .then(() => {
                refreshItems()
                updateGlobal()
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
            });
    }

    const handleAdd = () => {
        if (newItemText.trim() != "" && !addLoading) {
            const plan = new Plan();
            plan.idPaciente = props.idPaciente
            plan.idConsultation = consultation.idConsulta

            let newItem = { titulo: newItemText, isCompleted: false }

            setAddLoading(true);
            plan.addItem(JWT, newItem)
                .then((respuesta) => {
                    newItem.idItemplan = respuesta.idItemPlan
                    console.log(newItem)
                    setItems(items.concat([newItem]));
                    setNewItemText("")
                    setAddLoading(false);
                    updateGlobal()
                })
                .catch((error) => {
                    mySwallFetchErrorHandler(error, logoutHandler);
                    setAddLoading(false);
                });
        }
    }

    useEffect(() => {
        refreshItems()
      }, [])

    return (
        <LoadsWithSpinner variable={loading} height="50" text="Cargando plan">
            <div className="card shadow mb-4">
                <div className="card-header py-3" style={{ background: "rgb(78, 115, 223)" }}>
                    <h6 className="font-weight-bold m-0 py-2" style={{ color: "#ffffff", textAlign: 'center' }}>Plan</h6>
                    {!props.global && <h6 className=" m-0" style={{ textAlign: 'center', color: "#dddddd" }}>Consulta {consultation.numeroConsulta} - <small>{consultation.fechaString}</small></h6>}
                </div>
                <ul className="list-group list-group-flush">
                    <ItemsGenerator  />
                    {!props.global &&
                        <li className="list-group-item">
                            <div className="row align-items-center no-gutters">
                                <div className="col-lg-2">
                                    <LoadsWithSpinner variable={addLoading} height="50" >
                                        <button className="btn btn-primary btn-sm" onClick={handleAdd}>Agregar</button>
                                    </LoadsWithSpinner>
                                </div>
                                <div className="col mr-2" style={{ cursor: 'pointer' }} >
                                    <input type="text" onKeyDown={e=>e.key==='Enter' && handleAdd() } onChange={(event) => setNewItemText(event.target.value)} value={newItemText} className="form-control" />
                                </div>
                            </div>
                        </li>}
                </ul>
            </div>
        </LoadsWithSpinner>
    )

    function ItemsGenerator() {
        if (!props.global) {
            return items.map(item => <PlanItem key={item.idItemplan} global={props.global} {...item} handleStatusChange={(newStatus) => handleStatusChange(item.idItemplan, (item.idConsulta ? item.idConsulta : null), newStatus)} handleDelete={() => handleDelete(item.idItemplan)} />)
        } else {
            let actualConsultation = { idConsulta: null }
            return items.map(item => {
                let li = [<PlanItem key={item.idItemplan + '_global'} global={props.global} {...item} handleStatusChange={(newStatus) => handleStatusChange(item.idItemplan, (item.idConsulta ? item.idConsulta : null), newStatus)} handleDelete={() => handleDelete(item.idItemplan)} />]
                if (actualConsultation.idConsulta != item.idConsulta) {
                    actualConsultation.idConsulta = item.idConsulta
                    li = [<li className="list-group-item" style={{background:'#e1eaff'}}>
                        <div className="col mr-2" >
                            <h6 className="text-dark mb-0" style={{ textAlign: 'center' }}>Consulta {item.numeroConsulta} - <small>{(new Date(item.fecha)).toLocaleString("en-GB")}</small></h6>

                        </div>
                    </li>].concat(li)
                }
                return li
            })
        }
    }
    
    function refreshItems(silent = false) {
        if (!props.global) {
            const plan = new Plan()
            plan.idPaciente = props.idPaciente
            plan.idConsultation = consultation.idConsulta
            silent || setLoading(true)
            plan.getAllFromConsultation(JWT)
                .then(() => {
                    console.log(plan.items)
                    setItems(plan.items)
                    silent || setLoading(false)
                })
                .catch((error) => {
                    mySwallFetchErrorHandler(error, logoutHandler)
                    silent || setLoading(false)
                })
        } else {
            const plan = new Plan()
            plan.idPaciente = props.idPaciente
            silent || setLoading(true)
            plan.getAll(JWT)
                .then(() => {
                    console.log(plan.items)
                    setItems(plan.items)
                    silent || setLoading(false)
                })
                .catch((error) => {
                    mySwallFetchErrorHandler(error, logoutHandler)
                    silent || setLoading(false)
                })
        }
    }
})