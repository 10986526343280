//import ConsultationItem from './ConsultationItem'
import { jsonFetchPromise } from './FetchHelper'

export default class ConsultationItems {
    constructor() {
        this._idPaciente = ''
        this._idConsultation = ''
        this._items = []
    }

    set idConsultation(idConsultation) { this._idConsultation = idConsultation }
    get idConsultation() { return this._idConsultation }
    set items(items) { this._items = items }
    get items() { return this._items }
    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    get idPaciente() { return this._idPaciente }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/consultas/'+this._idConsultation+'/itemsconsulta',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const obj = json
                that._items = obj
            },
            jwt)
    }

    add(jwt,idTipoItemConsulta) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/consultas/'+this._idConsultation+'/itemsconsulta', 'POST', {}, (json) => { }, jwt, JSON.stringify({idTipoItemConsulta: idTipoItemConsulta}))
    }

    update(jwt,idItemConsulta,body) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/consultas/'+this._idConsultation+'/itemsconsulta/'+idItemConsulta, 'PUT', {}, (json) => { }, jwt, JSON.stringify({body: body}))
    }

    destacar(jwt,idItemConsulta,razon) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/consultas/'+this._idConsultation+'/itemsconsulta/'+idItemConsulta+'/destacar', 'POST', {}, (json) => { }, jwt, JSON.stringify({razon: razon}))
    }

    delete(jwt,idItemConsulta) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/consultas/'+this._idConsultation+'/itemsconsulta/'+idItemConsulta, 'DELETE', {}, (json) => { }, jwt)
    }
}
