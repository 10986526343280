import React from 'react'
import { FormikTextInput } from './components/FormikInputHelpers'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

function LogIn(props) {

    const submitHandler = (values) => {
        props.loginHandler(values.usuario, values.pw)
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-9 col-lg-12 col-xl-10">
                    <div className="card shadow-lg o-hidden border-0 my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-flex">
                                    <div className="flex-grow-1 bg-login-image" style={{ backgroundImage: 'url(https://s3.amazonaws.com/walrus-assets/img/AdamsNeilson_NarrativeMedicine_735.jpg)' }}></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h4 className="text-dark mb-4">Consultorio</h4>
                                        </div>
                                        <hr />
                                        <Formik
                                            initialValues={{
                                                usuario: '',
                                                pw: ''
                                            }}
                                            validationSchema={Yup.object({
                                                usuario: Yup.string()
                                                    .required('Requerido'),
                                                pw: Yup.string()
                                                    .required('Requerido')
                                            })}
                                            onSubmit={(values, { setSubmitting }) => {
                                                submitHandler(values)
                                                setSubmitting(false)
                                            }}
                                        >
                                            <Form className="user">
                                                <div className="form-group"><FormikTextInput className="form-control form-control-user" type="text" aria-describedby="emailHelp" placeholder="Usuario" name="usuario" /></div>
                                                <div className="form-group"><FormikTextInput className="form-control form-control-user" type="password" placeholder="Constraseña" name="pw" /></div>
                                                <br />
                                                <button className="btn btn-primary btn-block text-white btn-user" type="submit">Ingresar</button>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LogIn