import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import * as Scroll from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import RichText from "./RichTest";
import { useParams } from "react-router-dom";
import ConsultationItems from "../classes/ConsultationItems";
import mySwallFetchErrorHandler from "./mySwallFetchErrorHandler";

import AuthContext from "../AuthContext";
import TiposItemConsulta from "../classes/TiposItemConsulta";
import LoadsWithSpinner from "./LoadsWithSpinner";
import { Consultation as ConsultationModel } from "../classes/Consultation";
import ConsultationHeader from "./ConsultationHeader";
import ConsultationResults from "./ConsultationResults";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Consultations as ConsultationsModel } from "../classes/Consultations";
import ConsultationModal from "./ConsultationModal";

const MySwal = withReactContent(Swal);

export default function Consultation(props) {
  const { JWT, logoutHandler } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [consultationLoading, setConsultationLoading] = useState(true);
  const [loadingTipos, setLoadingTipos] = useState(true);
  const [loadingAdded, setLoadingAdded] = useState(false);
  const [consultationItems, setConsultationItems] = useState([]);
  const [consultationItemsChanges, setConsultationItemsChanges] = useState({});
  const [tiposItemConsulta, setTiposItemConsulta] = useState([]);
  const [lastAddedId, setLastAddedId] = useState(null);
  const [consultation, setConsultation] = useState(null);
  const [itemsActive, setItemsActive] = useState(true);
  const [consultations, setConsultations] = useState([]);
  const [activeModalConsultation, setActiveModalConsultation] = useState(null);

  let { idConsulta } = useParams();

  const ItemElement = (props) => (
    <Element
      key={props.item.idItemConsulta}
      name={"consultationitem_" + props.item.idItemConsulta}
    >
      <div className="row">
        <RichText
          {...Object.assign({ enabled: false }, props.item)}
          refreshDestacados={props.refreshDestacados}
          key={props.item.idItemConsulta}
          titulo={
            tiposItemConsulta.find(
              (tipo) => tipo.idTipoItemConsulta == props.item.idTipoItemConsulta
            ).titulo
          }
          updateHandler={(body) =>
            updateConsultationItem(props.item.idItemConsulta, body)
          }
          deleteHandler={() =>
            deleteConsultationItem(props.item.idItemConsulta)
          }
          destacarHandler={(razon) =>
            destacarConsultationItem(props.item.idItemConsulta, razon)
          }
        />
      </div>
    </Element>
  );

  function addItem({ idTipoItemConsulta }) {
    const existingItem = consultationItems.find(
      (item) => item.idTipoItemConsulta == idTipoItemConsulta
    );
    if (existingItem) {
      scrollTo(existingItem.idItemConsulta);
    } else {
      addConsultationItem(idTipoItemConsulta);
    }
  }

  function scrollTo(idItemConsulta) {
    scroller.scrollTo("consultationitem_" + idItemConsulta, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -100,
    });
  }

  function updatedItemsFromChanges() {
    return consultationItems.map((item) => {
      if (consultationItemsChanges.hasOwnProperty(item.idItemConsulta)) {
        const newBody = consultationItemsChanges[item.idItemConsulta].body;
        const newDestacado =
          consultationItemsChanges[item.idItemConsulta].estaDestacado;
        item.body = newBody ? newBody : item.body;
        item.estaDestacado = newDestacado ? newDestacado : item.estaDestacado;
        item.enabled = false;
      }
      return item;
    });
  }

  function addConsultationItem(idTipoItemConsulta) {
    let idNewItem = null;
    let newItems = updatedItemsFromChanges();

    const consultationItemsModel = newConsultationItemsModel();
    setLoadingAdded(true);
    consultationItemsModel
      .add(JWT, idTipoItemConsulta)
      .then((respuesta) => {
        newItems = consultationItems.concat([
          {
            idTipoItemConsulta: idTipoItemConsulta,
            idItemConsulta: respuesta.idItemConsulta,
            enabled: true,
            body: "",
            estaDestacado: 0,
          },
        ]);
        setLastAddedId(respuesta.idItemConsulta);
        setConsultationItems(newItems);
        setLoadingAdded(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoadingAdded(false);
      });

    return idNewItem;
  }

  const updateConsultationItem = (idItemConsulta, body) => {
    const consultationItemsModel = newConsultationItemsModel();
    return {
      update: consultationItemsModel.update(JWT, idItemConsulta, body),
      cb: () => {
        let obj = {};
        obj[idItemConsulta] = {};
        obj[idItemConsulta].body = body;
        setConsultationItemsChanges(
          Object.assign(consultationItemsChanges, obj)
        );
      },
    };
  };

  const destacarConsultationItem = (idItemConsulta, razon) => {
    const consultationItemsModel = newConsultationItemsModel();
    return {
      update: consultationItemsModel.destacar(JWT, idItemConsulta, razon),
      cb: () => {
        let obj = {};
        obj[idItemConsulta] = {};
        obj[idItemConsulta].estaDestacado = true;
        setConsultationItemsChanges(
          Object.assign(consultationItemsChanges, obj)
        );
        updatedItemsFromChanges();
      },
    };
  };

  const deleteConsultationItem = (idItemConsulta) => {
    scroll.scrollToTop({
      duration: 1000,
      smooth: true,
    });
    setLoading(true);
    const consultationItems = newConsultationItemsModel();
    consultationItems
      .delete(JWT, idItemConsulta)
      .then((respuesta) => {
        MySwal.fire("Borrado exitosamente", "", "success");
        refreshConsultationItems();
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoading(false);
      });
  };

  function getTiposItemConsulta() {
    setLoadingTipos(true);
    const tipos = new TiposItemConsulta();
    tipos
      .getAll(JWT)
      .then(() => {
        setTiposItemConsulta(tipos.tipos);
        setLoadingTipos(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoadingTipos(false);
      });
  }

  function getConsultation() {
    setConsultationLoading(true);
    const consultation = new ConsultationModel();
    consultation.idConsulta = idConsulta;
    consultation.idPaciente = props.idPaciente;
    consultation
      .get(JWT)
      .then(() => {
        setConsultation(consultation);
        setConsultationLoading(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setConsultationLoading(false);
      });
  }

  function refreshConsultationItems() {
    const consultationItemsModel = newConsultationItemsModel();

    setLoading(true);
    consultationItemsModel
      .getAll(JWT)
      .then(() => {
        setConsultationItems(consultationItemsModel.items);
        setLoading(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoading(false);
      });
  }

  function refreshConsultations() {
    const consultationsModel = new ConsultationsModel();
    consultationsModel.idPaciente = props.idPaciente;
    setLoading(true);
    consultationsModel
      .getAll(JWT)
      .then(() => {
        setConsultations(consultationsModel.consultations);
        setLoading(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoading(false);
      });
  }

  useEffect(() => {
    getTiposItemConsulta();
    getConsultation();
    refreshConsultationItems();
    refreshConsultations();
  }, []);

  useEffect(() => {
    if (lastAddedId) {
      setTimeout(() => scrollTo(lastAddedId), 500);
      setLastAddedId(null);
    }
  }, [consultationItems]);

  useEffect(() => {
    updatedItemsFromChanges();
  }, [itemsActive]);

  return (
    <LoadsWithSpinner variable={consultationLoading} text="Cargando consulta ">
      <LoadsWithSpinner
        variable={loading || loadingTipos}
        text="Cargando items"
      >
        <div className="card">
          <div
            className="card-header"
            role="tab"
            style={{ background: "white", borderBottom: 0 }}
          >
            <ConsultationHeader
              consultation={consultation}
              consultations={consultations}
              setActiveModalConsultation={setActiveModalConsultation}
              idPaciente={props.idPaciente}
            />
          </div>
          <div
            className="row"
            style={{
              background: "white",
              borderBottom: "1px solid black",
              margin: 0,
            }}
          >
            <div
              className="col-lg-6 m-0"
              style={{ padding: 0, paddingLeft: 10, paddingRight: 10 }}
            >
              <button
                className={"btn btn-primary py-1"}
                style={{
                  ...(itemsActive && {
                    background: "white",
                    color: "rgb(78, 115, 223)",
                  }),
                  ...(!itemsActive && {
                    "-webkit-box-shadow":
                      "inset 0px -11px 7px -9px rgba(0,0,0,0.75)",
                    "-moz-box-shadow":
                      "inset 0px -11px 7px -9px rgba(0,0,0,0.75)",
                    "box-shadow": "inset 0px -11px 7px -9px rgba(0,0,0,0.75)",
                  }),
                  marginBottom: "-1px",
                  width: "100%",
                  borderBottom: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                onClick={() => setItemsActive(true)}
              >
                Items
              </button>
            </div>
            <div
              className="col-lg-6 m-0 py-0"
              style={{ padding: 0, paddingLeft: 10, paddingRight: 10 }}
            >
              <button
                className={"btn btn-primary py-1"}
                style={{
                  ...(!itemsActive && {
                    background: "white",
                    color: "rgb(78, 115, 223)",
                  }),
                  ...(itemsActive && {
                    "-webkit-box-shadow":
                      "inset 0px -11px 7px -9px rgba(0,0,0,0.75)",
                    "-moz-box-shadow":
                      "inset 0px -11px 7px -9px rgba(0,0,0,0.75)",
                    "box-shadow": "inset 0px -11px 7px -9px rgba(0,0,0,0.75)",
                  }),
                  marginBottom: "-1px",
                  width: "100%",
                  borderBottom: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                onClick={() => setItemsActive(false)}
              >
                Resultados
              </button>
            </div>
          </div>
          <div
            className="item-1 collapse show"
            role="tabpanel"
            data-parent="#accordion-1"
          >
            <div
              className="card-body"
              style={{ display: itemsActive ? "block" : "none" }}
            >
              {consultationItems.map((item) => {
                return (
                  <ItemElement
                    item={item}
                    refreshDestacados={props.refreshDestacados}
                  />
                );
              })}
              <div className="row">
                <div className="col text-center">
                  <LoadsWithSpinner
                    variable={loadingAdded}
                    height="50"
                    text="Agregando "
                  >
                    <div className="btn-group dropup">
                      <button
                        type="button"
                        className="btn btn-primary text-center text-white rounded-circle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ fontSize: 22, width: 47, height: 47 }}
                      >
                        +
                      </button>
                      <div className="dropdown-menu" style={{ fontSize: 17 }}>
                        {!loadingTipos &&
                          tiposItemConsulta.map((item) => (
                            <button
                              key={item.idTipoItemConsulta}
                              onClick={() => addItem(item)}
                              className="dropdown-item"
                              href="#"
                            >
                              {item.titulo}
                            </button>
                          ))}
                      </div>
                    </div>
                  </LoadsWithSpinner>
                </div>
              </div>
            </div>

            <ConsultationResults
              display={!itemsActive}
              consultation={consultation}
              email={props.email}
              idPaciente={props.idPaciente}
              updateConsultation={(updatedConsultation) => {
                let newConsultation = consultation;
                newConsultation.tratamiento = updatedConsultation.tratamiento;
                newConsultation.objetivo = updatedConsultation.objetivo;
                setConsultation(newConsultation);
              }}
            />
          </div>
        </div>
        <ConsultationModal
          idConsulta={
            activeModalConsultation?.idConsulta
          }
          tiposItemConsulta={tiposItemConsulta}
          idPaciente={props.idPaciente}
          fechaConsulta={
            activeModalConsultation && activeModalConsultation.fechaString
          }
          show={activeModalConsultation}
          objetivo={activeModalConsultation && activeModalConsultation.objetivo}
          tratamiento={activeModalConsultation && activeModalConsultation.tratamiento}
          onHide={() => {
            setActiveModalConsultation(null);
            updatedItemsFromChanges();
          }}
        />
      </LoadsWithSpinner>
    </LoadsWithSpinner>
  );

  function newConsultationItemsModel() {
    const consultationItems = new ConsultationItems();
    consultationItems.idPaciente = props.idPaciente;
    consultationItems.idConsultation = idConsulta;
    return consultationItems;
  }
}
