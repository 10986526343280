import React, { useState, useEffect, useContext } from 'react'
import Consultation from './Consultation'
import LoadsWithSpinner from './LoadsWithSpinner'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import { Formik, Form } from 'formik';
import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'

import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../AuthContext'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import { Consultations as ConsultationsModel } from '../classes/Consultations'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Consultation as ConsultationModel } from '../classes/Consultation'

const MySwal = withReactContent(Swal)



export default function Consultations(props) {

    const { JWT, logoutHandler } = useContext(AuthContext)

    let { path } = useRouteMatch();
    let { url } = useRouteMatch();
    let history = useHistory();


    const [consultations, setConsultations] = useState([])
    const [loading, setLoading] = useState(true)

    function refreshConsultations() {
        const consultationsModel = new ConsultationsModel();
        consultationsModel.idPaciente = props.idPaciente
        setLoading(true);
        consultationsModel.getAll(JWT)
            .then(() => {
                setConsultations(consultationsModel.consultations);
                setLoading(false);
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoading(false);
            });
    }

    const deleteConsultation = (idConsulta, fecha) => {

        MySwal.fire({
            title: 'Va a borrar la consulta del ' + fecha,
            text: "¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Borrar'
        }).then((result) => {
            if (result.value) {

                const consulta = new ConsultationModel()
                consulta.idConsulta = idConsulta
                consulta.idPaciente = props.idPaciente
                setLoading(true)

                consulta.delete(JWT)
                    .then(() => {
                        MySwal.fire(
                            'Borrado exitosamente',
                            '',
                            'success'
                        )
                        setLoading(false)
                        refreshConsultations()
                    })
                    .catch((error) => {
                        mySwallFetchErrorHandler(error, logoutHandler)
                        setLoading(false)
                    })

            }
        })
    }

    const submitHandler = (formData) => {
        const consultation = new ConsultationModel()

        setLoading(true)

        consultation.idPaciente = props.idPaciente
        consultation.fecha = formData.fecha
        consultation.motivo = formData.motivo

        consultation.update(JWT)
            .then((respuesta) => {
                history.push(`${url}/consultas/` + respuesta.idConsulta)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setLoading(false)
            })

    }

    const NewConsultationForm = () => {
        return (
            <Formik
                initialValues={{
                    fecha: new Date(),
                    motivo: ''
                }}
                validationSchema={Yup.object({
                    fecha: Yup.date()
                        .required('Requerido'),
                    motivo: Yup.string()
                })}
                onSubmit={(values, { setSubmitting }) => {
                    submitHandler(values)
                    setSubmitting(false)
                }}
            >
                <Form className="form" style={{ padding: 20 }}>
                    <div className="row">
                        <div className="col">
                            <FormikDatePicker
                                className="form-control"
                                label="Fecha de consulta"
                                name="fecha"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="d MMMM, yyyy h:mm aa"
                            />
                        </div>

                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <FormikTextArea
                                label="Motivo de consulta"
                                name="motivo"
                                type="text"
                                placeholder=""
                                className="form-control"
                            />
                        </div>
                    </div>
                    <br />
                    <button className="btn btn-info btn-lg" type="submit" data-toggle="collapse" aria-expanded="true" aria-controls="newPatient" href="#newPatient">Agregar</button>
                </Form>
            </Formik>
        )
    }

    useEffect(() => {
        refreshConsultations()
    }, [])

    return (
        <Switch>
            <Route exact path={path}>
                <div className="card shadow" >
                    <div className="card-header py-3">
                        <div className="row"><div className="col">
                            <p className="text-primary m-0 font-weight-bold" style={{ fontSize: 20 }}>Consultas</p>
                        </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="shadow card">
                            <a className="btn btn-link text-left card-header font-weight-bold" data-toggle="collapse" aria-expanded="true" aria-controls="newPatient" href="#newPatient" role="button" >Nueva consulta</a>
                            <div className="collapse show" id="newPatient">
                                <div className="card-body">
                                    <NewConsultationForm />
                                </div>
                            </div>
                        </div>
                        <br />
                        <LoadsWithSpinner variable={loading} text="Cargando consultas ">
                            {consultations.map((consultation, index) =>

                                <div key={consultation.idConsulta} className="card shadow border-left-info py-2" style={{ marginBottom: 10 }}>
                                    <div className="card-body" style={{ padding: 10 }}>
                                        <div className="row align-items-center no-gutters">

                                            <div className="col mr-2">
                                                <Link to={`${url}/consultas/` + consultation.idConsulta} style={{ textDecoration: 'none' }}>
                                                    <div className="text-uppercase text-success font-weight-bold text-xs mb-1"><span style={{ color: "rgb(179,179,179)" }}>{consultation.fechaString}</span></div>
                                                    <div className="text-dark font-weight-bold h5 mb-0"><span>Consulta {consultations.length - index}</span></div>
                                                    <div className="font-weight-bold mb-1"><span style={{ color: "rgb(179,179,179)" }}>{consultation.motivo}</span></div>
                                                </Link>
                                            </div>
                                            <div className="col-lg-1">
                                                <a onClick={() => deleteConsultation(consultation.idConsulta, consultation.fechaString)} style={{ fontSize: 30, padding: 5, color: 'darkred', cursor: 'pointer' }}><FontAwesomeIcon icon="trash" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </LoadsWithSpinner>
                    </div>
                </div>
            </Route>
            <Route path={`${path}/consultas/:idConsulta`}>
                <Consultation refreshDestacados={props.refreshDestacados} email={props.email} idPaciente={props.idPaciente}/>
            </Route>
        </Switch>
    )
} 