import TimeLineItem from './TimeLineItem'
import { jsonFetchPromise } from './FetchHelper'

export class TimeLine {    
    constructor() {
        this._idPaciente = ''
        this._timeLineItems =[]
    }

    set timeLineItems(timeLineItems) { this._timeLineItems = timeLineItems }
    get timeLineItems() { return this._timeLineItems }
    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    get idPaciente() { return this._idPaciente }

    getAll(jwt) {
        var that = this
        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/timeline',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                console.log(json)
               that._timeLineItems = json.map((timeLineItems) => Object.assign(new TimeLineItem(), timeLineItems))
            },
            jwt)
    }
}