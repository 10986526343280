import React from 'react'
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from 'moment'
import es from 'date-fns/locale/es';
registerLocale('es', es)

export const FormikTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className={props.className} {...field} {...props} />
      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};

export const FormikTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className={props.className} {...field} {...props} />
      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};

export const FormikCheckBox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <label className="checkbox">
        <input type="checkbox" className={props.className} {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};


export const FormikFileInput = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <>
      <label className="" htmlFor={props.id || props.name}>{label}</label>
      <input type="file" className={props.className} name={props.name} id={props.name} accept={props.anyFile ? "" : "image/*"} onChange={(event) => {
        setFieldValue(field.name, event.currentTarget.files[0]);
      }} />

      {!field.value && props.children}
      {!props.anyFile && <Thumb file={field.value} />}

      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};

export const FormikSelect = ({ label, ...props }) => {

  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select className={props.className} {...field} {...props} />
      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};



export const FormikDatePicker = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <DatePicker
        {...field}
        {...props}
        className={props.className}
        dateFormat={props.dateFormat || "dd/MM/yyyy"}
        showYearDropdown
        locale="es"
        selected={(field.value && new Date(field.value)) || props.selected}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
      />
      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};

export const FormikYearPicker = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <DatePicker
        {...field}
        {...props}
        className={props.className}
        dateFormat={"yyyy"}
        showYearPicker
        locale="es"
        selected={(field.value && new Date(field.value)) || props.selected || new Date()}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
      />
      {meta.touched && meta.error ? (
        <small className="form-text" style={{ color: 'darkred' }}>{meta.error}</small>
      ) : null}
    </>
  );
};

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.file !== nextProps.file) {
      if (!nextProps.file) { return; }

      this.setState({ loading: true }, () => {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };

        reader.readAsDataURL(nextProps.file);
      });
    }


  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (<img src={thumb}
      alt={file.name}
      className="img-thumbnail mt-2"
      style={{ height: 200 }} />);
  }
}

export default { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikCheckBox }