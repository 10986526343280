import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect, FormikTextArea } from './FormikInputHelpers'
import LoadsWithSpinner from './LoadsWithSpinner'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useHistory } from "react-router-dom";

import AuthContext from '../AuthContext'

import Patient from '../classes/Patient'
import Prepagas from '../classes/Prepagas'

const MySwal = withReactContent(Swal)
// And now we can use these

const PatientsForm = (props) => {

  let history = useHistory();
  const { JWT, logoutHandler } = useContext(AuthContext)
  let { idPaciente } = useParams()
  const [loading, setLoading] = useState(false)
  const patient = props.patient ? props.patient : new Patient()

  const [prepagas, setPrepagas] = useState(null)

  const editOrNew = (editReturn, newReturn) => {
    return (idPaciente != null) ? editReturn : newReturn
  }

  function getPrepagas() {
    const prepagasModel = new Prepagas();
    prepagasModel.getAll(JWT)
      .then(() => {
        setPrepagas(prepagasModel.prepagas)
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        history.push("/pacientes");
      });
  }



  const submitHandler = (formData) => {

    const patient = new Patient()

    patient.idPaciente = formData.idPaciente
    patient.nombre = formData.nombre
    patient.apellido = formData.apellido
    patient.mail = formData.mail
    patient.idPrepaga = formData.idPrepaga
    patient.numeroAsociado = formData.numeroAsociado
    patient.fechaNacimiento = formData.fechaNacimiento
    patient.celular = formData.celular
    patient.imagenPaciente = formData.imagenPaciente
    patient.cuit = formData.cuit
    patient.descripcionGeneral = formData.descripcionGeneral

    setLoading(true);
    patient.update(JWT)
      .then((respuesta) => {
        MySwal.fire({
          icon: 'success',
          html: editOrNew(<p>Paciente {formData.nombre + ' ' + patient.apellido} modificado</p>, <p>Paciente {formData.nombre + ' ' + patient.apellido} agregado</p>),
          confirmButtonText: 'Ok!'
        }).then((result) => {
          if (result.value) {
            (formData.idPaciente && history.goBack()) || history.push("/pacientes/"+respuesta.idPaciente)
          }
        })
        setLoading(false)
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler)
        setLoading(false)
      })

  }

  useEffect(() => {
    getPrepagas()
  }, [])

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Gestión de pacientes</h3>
      <div className="card shadow">
        <div className="card-header py-3">
          <div className="row">
            <div className="col">
              <p className="text-primary m-0 font-weight-bold" style={{ fontSize: '25px' }}>Nuevo paciente <small style={{ color: '#bbbbbb' }}>(*) requerido</small></p>
            </div>
            <div className="col-lg-3">
              <Link onClick={history.goBack}>
                <h6 className="float-right">Cancelar y volver&nbsp;&nbsp;<button className="btn btn-primary text-center text-white rounded-circle" role="button" style={{ fontSize: '15px' }}><FontAwesomeIcon icon="undo" /></button></h6>
              </Link>
            </div>
          </div>
        </div>
        {!prepagas ? <img style={{ margin: '0 auto' }} height="100" src="https://networking.report/images/01loading.gif"></img> :
          <Formik
            initialValues={{
              nombre: patient.nombre,
              apellido: patient.apellido,
              mail: patient.mail,
              idPrepaga: patient.idPrepaga,
              numeroAsociado: patient.numeroAsociado,
              fechaNacimiento: patient.fechaNacimiento,
              celular: patient.celular,
              imagenPaciente: patient.imagenPaciente,
              cuit: patient.cuit,
              descripcionGeneral: patient.descripcionGeneral
            }}
            validationSchema={Yup.object({
              nombre: Yup.string()
                .required('Requerido'),
              apellido: Yup.string()
                .required('Requerido'),
              mail: Yup.string()
                .email('Ingrese email válido'),
              idPrepaga: Yup.number()
                .required('Seleccione alguna prepaga'),
              celular: Yup.number()
                .typeError('Solo se aceptan números')
                .required('Requerido'),
              cuit: Yup.number()
                .typeError('Solo se aceptan números'),
              numeroAsociado: Yup.number()
                .typeError('Solo se aceptan números')
            })}
            onSubmit={(values, { setSubmitting }) => {
              const idPaciente = patient.idPaciente != '' ? patient.idPaciente : null
              console.log(values)
              submitHandler({ ...values, idPaciente: idPaciente })
              setSubmitting(false)
            }}
          >
            <Form className="form" style={{ padding: 20 }}>
              <br />
              <div className="row">
                <div className="col">
                  <FormikTextInput
                    label="Nombre (*)"
                    name="nombre"
                    type="text"
                    placeholder=""
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <FormikTextInput
                    label="Apellido (*)"
                    name="apellido"
                    type="text"
                    placeholder=""
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <FormikTextInput
                    label="Celular (*)"
                    name="celular"
                    type="text"
                    placeholder=""
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <FormikTextInput
                    label="Mail"
                    name="mail"
                    type="email"
                    placeholder=""
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <FormikSelect label="Prepaga (*)" name="idPrepaga" className="form-control">
                    <option value="" label="Seleccionar prepaga" />
                    {prepagas.map(prepaga => <option key={prepaga.idPrepaga} value={prepaga.idPrepaga}>{prepaga.nombre}</option>)}
                  </FormikSelect>
                </div>
                <div className="col">
                  <FormikTextInput
                    className="form-control"
                    label="Número asociado"
                    name="numeroAsociado"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <FormikDatePicker
                    className="form-control"
                    label="Fecha de nacimiento"
                    name="fechaNacimiento"
                  />
                </div>
                <div className="col">
                  <FormikTextInput
                    className="form-control"
                    label="CUIT/CUIL"
                    name="cuit"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
              <br />
              <div className="form-group">
                <FormikFileInput
                  className="form-control"
                  label="Foto del paciente"
                  name="imagenPaciente"
                >
                  {editOrNew(<><small>Actual: </small><img src={'https://api.medicinafuncional.com.ar/uploads/' + patient.urlImagen}
                    className="img-thumbnail mt-2"
                    style={{ height: 200 }}
                  /></>, null)}
                </FormikFileInput>
              </div>
              <div className="form-group">
                <FormikTextArea
                  className="form-control"
                  label="Descripción general del paciente"
                  name="descripcionGeneral"
                />
              </div>
              <br />
              <LoadsWithSpinner variable={loading}>
                <button className="btn btn-info btn-lg" type="submit">{idPaciente ? "Editar" : "Crear"}</button>
              </LoadsWithSpinner>
            </Form>
          </Formik>
        }
      </div>
    </div>
  );
};

export default PatientsForm;

