import React, { useEffect, useState, useContext } from 'react'

import { Link, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'


import { MDBDataTable } from 'mdbreact';
import Patients from '../classes/Patients';
import Patient from '../classes/Patient';

import LoadsWithSpinner from './LoadsWithSpinner';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export default function () {

  const { JWT, logoutHandler } = useContext(AuthContext)

  let { url } = useRouteMatch();
  const [patientsData, setPatientsData] = useState([])
  const [patientsLoading, setPatientsLoading] = useState(false)

  const data = {
    columns: [
      {
        label: '',
        field: 'img',
      },
      {
        label: 'Nombre',
        field: 'nombre',
      },
      {
        label: 'Fecha Nacimiento',
        field: 'fechaNacimiento',
      },
      {
        label: 'Mail',
        field: 'mail',
      },
      {
        label: 'Celular',
        field: 'celular',
      },
      {
        label: 'Fecha última consulta',
        field: 'fechaUltimaConsulta',
      },
      {
        label: ' ',
        field: 'edits'
      }
    ],
    rows: patientsData
  };


  const deletePatient = (nombre, idPaciente) => {

    MySwal.fire({
      title: 'Va a borrar a ' + nombre,
      text: "¿Está seguro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Borrar'
    }).then((result) => {
      if (result.value) {

        const patient = new Patient()
        patient.idPaciente = idPaciente

        setPatientsLoading(true)

        patient.delete(JWT)
          .then(() => {
            MySwal.fire(
              'Borrado exitosamente',
              '',
              'success'
            )
            setPatientsLoading(false)
            refreshTable(setPatientsLoading, setPatientsData, url);
          })
          .catch((error) => {
            mySwallFetchErrorHandler(error, logoutHandler)
            setPatientsLoading(false)
          })

      }
    })
  }

  function refreshTable(setPatientsLoading, setPatientsData, url) {
    const patientsModel = new Patients();
    setPatientsLoading(true);
    patientsModel.getAll(JWT)
      .then(() => {
        setPatientsData(patientsModel.patients.map((patient) => {
          let fechaNacimiento
          if (!patient.fechaNacimiento) {
            fechaNacimiento = ''
          } else {
            fechaNacimiento = new Date(patient.fechaNacimiento);
            fechaNacimiento.setDate(fechaNacimiento.getDate() + 1);
            fechaNacimiento = fechaNacimiento.toLocaleDateString()
          }

          return {
            img: <Link to={`${url}/` + patient.idPaciente} style={{textDecoration:'none',color:'#666666', fontWeight:'bold'}}><img width="100" height="100" style={{ 'object-fit': 'cover', border: '1px solid black', borderRadius: '50%' }} src={'https://api.medicinafuncional.com.ar/uploads/' + patient.urlImagen} /></Link>,
            nombre: patient.nombre.toUpperCase() + " " + patient.apellido.toUpperCase(),
            fechaNacimiento: fechaNacimiento,
            mail: patient.mail.toUpperCase(),
            celular: patient.celular,
            fechaUltimaConsulta: patient.lastConsultation ? (new Date(patient.lastConsultation)).toLocaleDateString('es-CL') : '-',
            edits: <><Link to={`${url}/` + patient.idPaciente} style={{ fontSize: 30, padding: 5, marginRight:5 }}><FontAwesomeIcon icon="notes-medical" /></Link><Link to={`${url}/edit/` + patient.idPaciente} style={{ fontSize: 30, padding: 5 }}><FontAwesomeIcon icon="edit" /></Link> <a onClick={() => deletePatient(patient.nombre + " " + patient.apellido, patient.idPaciente)} style={{ fontSize: 30, padding: 5, color: 'darkred', cursor: 'pointer' }}><FontAwesomeIcon icon="trash" /></a> </>,
          };
        }));
        setPatientsLoading(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setPatientsLoading(false);
      });
  }

  useEffect(() => {
    refreshTable(setPatientsLoading, setPatientsData, url);
  }, []);

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Gestión de pacientes</h3>
      <div className="card shadow">
        <div className="card-header py-3">
          <div className="row">
            <div className="col">
              <p className="text-primary m-0 font-weight-bold" style={{ fontSize: '25px' }}>Pacientes</p>
            </div>
            <div className="col-lg-3">
              <Link to={`${url}/new`}>
                <h6 className="float-right">Nuevo paciente&nbsp;&nbsp;<button className="btn btn-primary text-center text-white rounded-circle" role="button" style={{ fontSize: '15px' }}><FontAwesomeIcon icon="plus" /></button></h6>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body" >
          <LoadsWithSpinner variable={patientsLoading} text="Cargando pacientes ">
            <MDBDataTable
              data={data}
              responsive
              searching={true}
              sortable={true}
              paginationLabel={['Anterior', 'Siguiente']}
              infoLabel={['Mostrando', 'a', 'de', 'pacientes']}
              entriesLabel={'Cantidad de pacientes por página'}
              searchLabel={'Buscar'}
            />
          </LoadsWithSpinner>
        </div>
      </div>
    </div>
  )
}

