import React, { useState, useContext, useEffect, Suspense } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import AuthContext from "../AuthContext";
import {
  FormikDatePicker,
  FormikFileInput,
  FormikTextInput,
  FormikSelect,
  FormikTextArea,
} from "./FormikInputHelpers";
import LoadsWithSpinner from "./LoadsWithSpinner";
import mySwallFetchErrorHandler from "./mySwallFetchErrorHandler";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { Consultation as ConsultationModel } from "../classes/Consultation";

export default function ConsultationHeader(props) {
  const { JWT, logoutHandler } = useContext(AuthContext);

  const [consultation, setConsultation] = useState(props.consultation);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  let { idConsulta } = useParams();

  const editorStateChange = () => {
    setEditing(!editing);
  };

  const submitHandler = (values) => {
    setLoading(true);
    const consultationModel = new ConsultationModel();
    consultationModel.idConsulta = idConsulta;
    consultationModel.idPaciente = props.idPaciente;
    consultationModel.motivo = values.motivo;
    consultationModel.fecha = values.fecha;
    consultationModel.numeroConsulta = consultation.numeroConsulta;

    console.log(consultationModel);

    consultationModel
      .update(JWT)
      .then(() => {
        consultationModel.plus3hours();
        setConsultation(consultationModel);
        setLoading(false);
        editorStateChange();
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoading(false);
      });
  };

  return (
    <div className="row">
      <LoadsWithSpinner variable={loading} height="50" text="Guardando ">
        {!editing ? (
          <div className="col-lg-7">
            <h3 className="text-dark h3 mb-0">
              Consulta {consultation.numeroConsulta}
            </h3>
            <p style={{ marginBottom: 0 }}>
              {consultation && consultation.fechaString}
            </p>
            <p style={{ marginBottom: 0 }}>
              {(consultation && consultation.motivo) || "Sin motivo cargado"}
            </p>
          </div>
        ) : (
          <div className="col-lg-7">
            <h4 className="mb-0 text-dark">
              Consulta {consultation.numeroConsulta}
            </h4>
            <br />
            <Formik
              initialValues={{
                fecha: new Date(consultation.fecha),
                motivo: consultation.motivo,
              }}
              validationSchema={Yup.object({
                fecha: Yup.date().required("Requerido"),
                motivo: Yup.string(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                submitHandler(values);
                setSubmitting(false);
              }}
            >
              <Form className="form">
                <FormikDatePicker
                  className="form-control"
                  label="Fecha de consulta"
                  name="fecha"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="d MMMM, yyyy h:mm aa"
                />
                <br />
                <br />
                <FormikTextArea
                  label="Motivo de consulta"
                  name="motivo"
                  type="text"
                  placeholder=""
                  className="form-control"
                />

                <br />
                <button
                  className="btn btn-info btn-md"
                  type="submit"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="newPatient"
                  href="#newPatient"
                >
                  Modificar
                </button>
                <button
                  className="btn btn-default btn-md"
                  onClick={editorStateChange}
                >
                  Cancelar
                </button>
              </Form>
            </Formik>
          </div>
        )}
        <div className="col-lg-5">
          <div className="btn-group dropdown float-right ml-3">
            <button
              type="button"
              className="btn btn-default text-center"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ fontSize: 17, borderRadius: 10 }}
            >
              Otras consultas
            </button>
            <div className="dropdown-menu" style={{ fontSize: 17, width: 300 }}>
              {props.consultations.map((consultation, index) => (
                <button
                  className="dropdown-item"
                  href="#"
                  onClick={() => props.setActiveModalConsultation(consultation)}
                >
                  Consulta {props.consultations.length - index} -{" "}
                  {consultation.fechaString}
                </button>
              ))}
            </div>
          </div>
          <Link to={"/pacientes/" + props.idPaciente}>
            <h6 className="float-right">
              Volver&nbsp;&nbsp;
              <button
                className="btn btn-primary text-center text-white rounded-circle"
                role="button"
                style={{ fontSize: "15px" }}
              >
                <FontAwesomeIcon icon="undo" />
              </button>
            </h6>
          </Link>
          {!editing && (
            <a onClick={editorStateChange} style={{ cursor: "pointer" }}>
              <h6 className="float-right" style={{ marginRight: 15 }}>
                Editar&nbsp;&nbsp;
                <button
                  className="btn btn-warning text-center text-white rounded-circle"
                  role="button"
                  style={{ fontSize: "15px" }}
                >
                  <FontAwesomeIcon icon="edit" />
                </button>
              </h6>
            </a>
          )}
        </div>
      </LoadsWithSpinner>
    </div>
  );
}
