import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikDatePicker, FormikFileInput, FormikTextInput, FormikSelect } from './FormikInputHelpers'
import LoadsWithSpinner from './LoadsWithSpinner';
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Archivo from '../classes/Archivo';
import {
    useHistory,
    useParams,
    Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MySwal = withReactContent(Swal)

//const { idArchivo } = useParams();

export default function ArchivoForm(props) {
    const { idArchivo } = useParams();
    const { JWT, logoutHandler } = useContext(AuthContext)
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [loadingArchivo, setLoadingArchivo] = useState(false)
    const [archivo, setArchivo] = useState(null)

    const getArchivo = () => {
        setLoadingArchivo(true)
        const archivo = new Archivo(idArchivo)
        archivo.get(JWT)
            .then(() => {
                setArchivo(archivo)
                setLoadingArchivo(false)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoadingArchivo(false)
            });
    }
    const submitHandler = (values) => {
        setLoading(true)
        const newArchivo = new Archivo(idArchivo, values.nombre, values.descripcion, values.archivo)
        newArchivo.update(JWT).then((respuesta) => {
            MySwal.fire({
                icon: 'success',
                text: "Archivo agregado correctamente!",
                confirmButtonText: 'Ok!'
            }).then((result) => {
                if (result.value) {
                    history.push("/archivos")
                }
            })
            setLoading(false)
        })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setLoading(false)
            })
    }
    useEffect(() => {
        if (idArchivo != null)
            getArchivo()
    }, [])
    return (

        <>
            <div className="container-fluid">
                <div className="card shadow">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col">
                                <p className="text-primary m-0 font-weight-bold" style={{ fontSize: '25px' }}>Nuevo archivo <small style={{ color: '#bbbbbb' }}>(*) requerido</small></p>
                            </div>
                            <div className="col-lg-3">
                                <Link onClick={history.goBack}>
                                    <h6 className="float-right">Cancelar y volver&nbsp;&nbsp;<button className="btn btn-primary text-center text-white rounded-circle" role="button" style={{ fontSize: '15px' }}><FontAwesomeIcon icon="undo" /></button></h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <LoadsWithSpinner variable={loadingArchivo} text="Cargando archivo...">
                        <div className="row" style={{ padding: 20 }}>
                            <div style={{ width: '100%', margin: '0 auto' }}>
                                <Formik
                                    initialValues={{
                                        descripcion: archivo != null ? archivo.descripcion() : '',
                                        nombre: archivo != null ? archivo.nombre() : '',
                                        archivo: ''
                                    }}
                                    validationSchema={Yup.object({
                                        descripcion: Yup.string()
                                            .nullable(),
                                        nombre: Yup.string()
                                            .required('Requerido'),
                                        archivo: archivo && archivo.urlArchivo() ? Yup.string()
                                            .nullable() : Yup.string().required()
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        submitHandler(values)
                                        setSubmitting(false)
                                    }}
                                >
                                    <Form className="form" style={{ width: '50%', margin: '0 auto' }}>
                                        <div className="pricingTable purple">
                                            <div className="pricingTable-header">
                                                <FormikTextInput
                                                    name="nombre"
                                                    type="text"
                                                    label="Nombre (*)"
                                                    placeholder=""
                                                    className="form-control"
                                                />
                                                <FormikTextInput
                                                    name="descripcion"
                                                    type="text"
                                                    label="Descripción"
                                                    placeholder=""
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="pricingContent">
                                                <FormikFileInput
                                                    className="form-control"
                                                    label={archivo && archivo.urlArchivo() ? "Cambiar archivo" : "Adjuntar archivo (*)"}
                                                    name="archivo"
                                                    anyFile={true}
                                                />
                                                {archivo && archivo.urlArchivo() ? <a target="_blank" href={archivo.urlArchivo()}>Ver archivo actual</a> : ""}
                                            </div>
                                            <br />
                                            <LoadsWithSpinner variable={loading}>
                                                <button className="btn btn-info" style={{ width: '100%' }} type="submit">Guardar</button>
                                            </LoadsWithSpinner>
                                        </div>
                                    </Form>

                                </Formik>
                            </div>
                        </div>
                    </LoadsWithSpinner>
                </div>
            </div>

        </>

    )
}
