import React, { useState, useEffect, useContext } from "react";
import "./Estudio.css";
import Estudio from "../classes/Estudio";
import ResultadoCampo from "../classes/ResultadoCampo";
import moment from "moment";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBInput,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useRouteMatch } from "react-router-dom";
import mySwallFetchErrorHandler from "./mySwallFetchErrorHandler";
import AuthContext from "../AuthContext";

import "moment/locale/es"; // without this line it didn't work

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import Comparador from "../classes/Comparador";
import LoadsWithSpinner from "./LoadsWithSpinner";
import Estudios from "../classes/Estudios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

moment.locale("es");

const columns = [
  {
    label: "Tipo",
    field: "tipoEstudio",
  },
  {
    label: "Descripcion",
    field: "descripcion",
  },
  {
    label: "Fecha",
    field: "fechaEstudio",
  },
  {
    label: "Archivo",
    field: "archivoEstudio",
  },
  {
    label: "",
    field: "edit",
  },
  {
    label: "",
    field: "check",
  },
];

export default function EstudioTable({ patient }) {
  let { url } = useRouteMatch();
  const { JWT, logoutHandler } = useContext(AuthContext);

  const [estudios, setEstudios] = useState([]);
  const [comparador, setComparador] = useState();
  const [listosParaComparar, setListosParaComparar] = useState(0);
  const [comparando, setComparando] = useState([]);
  const [campoActual, setCampoActual] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [loadingEstudios, setLoadingEstudios] = useState(false);
  const [loadingComparador, setLoadingComparador] = useState(false);

  const [cargandoEstudio, setCargandoEstudio] = useState(false);
  const [estudioActivo, setEstudioActivo] = useState(null);

  const estudioCheckboxChange = (event) => {
    const idEstudio = event.target.value;
    setComparando((prev) => {
      if (
        !prev.some((estudioActual) => estudioActual.idEstudio() == idEstudio)
      ) {
        const estudioPertinente = estudios.find(
          (estudioActual) => estudioActual.idEstudio() == idEstudio
        );
        return prev.concat([estudioPertinente]);
      } else {
        return prev.filter(
          (estudioActual) => estudioActual.idEstudio() != idEstudio
        );
      }
    });
  };

  const cargarEstudio = (estudio) => {
    setComparador(null);
    setEstudioActivo(estudio);
    setCargandoEstudio(true);
    estudio
      .getCampos(JWT)
      .then(() => {
        setEstudioActivo(estudio);
        setCargandoEstudio(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setCargandoEstudio(false);
      });
  };

  const comparar = () => {
    setListosParaComparar(0);
    setLoadingComparador(true);
    comparando.forEach((estudio) => {
      estudio
        .getCampos(JWT)
        .then(() => {
          setListosParaComparar((count) => count + 1);
          setLoadingComparador(false);
        })
        .catch((error) => {
          mySwallFetchErrorHandler(error, logoutHandler);
          setLoadingComparador(false);
        });
    });

    setComparador(new Comparador(comparando));
  };

  const reiniciar = () => {
    setComparador();
    setComparando([]);
  };

  const ModalEstudio = () => {
    return (
      estudioActivo && (
        <MDBModal
          isOpen={estudioActivo}
          toggle={() => setEstudioActivo(null)}
          size="lg"
        >
          <MDBModalHeader toggle={() => setEstudioActivo(null)}>
            Resultados
          </MDBModalHeader>
          <MDBModalBody>
            <LoadsWithSpinner variable={cargandoEstudio}>
              <TablaEstudio estudio={estudioActivo} individual={true} />
            </LoadsWithSpinner>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={() => setEstudioActivo(null)}>
              Cerrar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      )
    );
  };

  const deleteEstudio = (idEstudio, fecha, tipo) => {
    MySwal.fire({
      title: "Va a el estudio (" + tipo + ") del " + fecha,
      text: "¿Está seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.value) {
        const estudioABorrar = new Estudio(idEstudio, patient.idPaciente);
        estudioABorrar
          .delete(JWT)
          .then(() => {
            MySwal.fire("Borrado exitosamente", "", "success");
            refreshEstudios();
          })
          .catch((error) => {
            mySwallFetchErrorHandler(error, logoutHandler);
          });
      }
    });
  };

  useEffect(() => {
    setListosParaComparar(0);
  }, [comparando]);

  const colorArray = {
    Hormonas: "primary",
    General: "warning",
    Hemograma: "danger",
    Antropometría: "success",
    "Hormonas y Hemograma": "info",
  };

  useEffect(() => {
    setDataTable(
      estudios.map((estudio) => {
        return {
          tipoEstudio: (
            <a
              className={"btn btn-" + colorArray[estudio.stringTipo()]}
              style={{ color: "white" }}
              onClick={() => {
                cargarEstudio(estudio);
                reiniciar();
              }}
            >
              {estudio.stringTipo()}
            </a>
          ),
          descripcion: (
            <a
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => {
                cargarEstudio(estudio);
              }}
            >
              {estudio.descripcion()}
            </a>
          ),
          fechaEstudio: estudio.stringFecha(),
          archivoEstudio: estudio.linkArchivo() ? (
            <a
              href={estudio.linkArchivo()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar
            </a>
          ) : (
            "-"
          ),
          check: (
            <MDBInput
              onChange={estudioCheckboxChange}
              label=""
              style={{ position: "relative", minWidth: 20 }}
              type="checkbox"
              value={estudio.idEstudio()}
              id={estudio.idEstudio()}
            />
          ),
          edit: (
            <>
              <Link to={`${url}/` + estudio.idEstudio() + `/edit/`}>
                <FontAwesomeIcon icon="edit" />
              </Link>
              <a
                onClick={() =>
                  deleteEstudio(
                    estudio.idEstudio(),
                    estudio.stringFecha(),
                    estudio.stringTipo()
                  )
                }
                style={{ cursor: "pointer", marginLeft: 10, color: "darkred" }}
              >
                <FontAwesomeIcon icon="trash" />
              </a>
            </>
          ),
        };
      })
    );
  }, [estudios]);

  const refreshEstudios = () => {
    setLoadingEstudios(true);
    const estudiosRepo = new Estudios(patient.idPaciente);
    estudiosRepo
      .getAll(JWT)
      .then(() => {
        setEstudios(estudiosRepo.estudios);
        setLoadingEstudios(false);
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, logoutHandler);
        setLoadingEstudios(false);
      });
  };

  useEffect(() => {
    refreshEstudios();
  }, []);

  const cambiarCampoActual = (tipoCampo) =>
    setCampoActual((tipoActual) =>
      !isEquivalent(tipoActual, tipoCampo) ? tipoCampo : null
    );

  const TablaEstudio = ({ estudio, individual }) => {
    const columnSize = 12 / (comparador ? comparador.cantidadEstudios() : 1);
    console.log(estudio);
    return (
      <div
        className={
          "col-sm-" +
          columnSize +
          " col-md-" +
          columnSize +
          " col-lg-" +
          columnSize +
          ""
        }
      >
        <div className="pricingTable purple">
          <div className="pricingTable-header">
            <h3>{estudio.stringTipo()}</h3>
            <h3 style={{ color: "#666666" }}>{estudio.stringFecha()}</h3>
          </div>
          <div className="pricingContent">
            <ul>
              {estudio.campos().map((campo) => {
                if (!campo) {
                  return (
                    <li style={{ fontSize: 12, padding: 0 }}>
                      No específicado
                      <span
                        className="d-block"
                        style={{ fontSize: 14, color: "rgb(80,81,88)" }}
                      >
                        -
                      </span>
                    </li>
                  );
                }

                return (
                  <li
                    onClick={() =>
                      !individual && cambiarCampoActual(campo.tipoCampo())
                    }
                    style={{
                      cursor: "pointer",
                      fontSize: 12,
                      padding: 0,
                      fontWeight: isEquivalent(campoActual, campo.tipoCampo())
                        ? "bold"
                        : "normal",
                    }}
                    className={
                      campo.positivo() == null ||
                      (campo.positivo() > 0 ? "positivo" : "negativo")
                    }
                  >
                    {campo.nombreTipoCampo()}
                    <span
                      className="d-block"
                      style={{ fontSize: 14, color: "rgb(80,81,88)" }}
                    >
                      {campo.valor()
                        ? campo.valor() +
                          " " +
                          (campo.unidad() != "Si/No" ? campo.unidad() : "")
                        : "Normal"}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="pricingTable-sign-up" style={{ padding: 10 }}>
            <p>
              <b> {estudio.descripcion()}</b>
            </p>
            <hr />
            <p>{estudio.comentarios()}</p>
          </div>
        </div>
      </div>
    );
  };

  const formatXAxis = (tickItem) => {
    return moment(tickItem).format("D MMM YYYY");
  };

  return (
    <>
      <ModalEstudio />
      <div className="container-fluid">
        <div className="d-sm-flex justify-content-between align-items-center mb-4">
          <h3 className="text-dark mb-4">
            Gestión de estudios ({patient.nombre + " " + patient.apellido})
          </h3>
          <Link
            to={"/pacientes/" + patient.idPaciente}
            style={{}}
            className="btn btn-primary btn-sm d-none d-sm-inline-block"
          >
            Volver al Paciente
          </Link>
        </div>
        <LoadsWithSpinner variable={loadingEstudios} text="Cargando estudios">
          <div className="card shadow">
            <div className="card-header py-3">
              <div className="row">
                <div className="col">
                  <p
                    className="text-primary m-0 font-weight-bold"
                    style={{ fontSize: "25px" }}
                  >
                    Estudios
                  </p>
                </div>
                <div className="col-lg-3">
                  <Link to={`${url}/new`}>
                    <h6 className="float-right">
                      Nuevo estudio&nbsp;&nbsp;
                      <button
                        className="btn btn-primary text-center text-white rounded-circle"
                        role="button"
                        style={{ fontSize: "15px" }}
                      >
                        <FontAwesomeIcon icon="plus" />
                      </button>
                    </h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form>
                <MDBTable btn fixed>
                  <MDBTableHead columns={columns} />
                  <MDBTableBody rows={dataTable} />
                </MDBTable>
              </form>
              <LoadsWithSpinner
                variable={
                  loadingComparador ||
                  (listosParaComparar > 0 &&
                    listosParaComparar != comparando.length)
                }
              >
                <button
                  disabled={comparando.length <= 1}
                  onClick={comparar}
                  className="btn btn-primary"
                  style={{ width: "100%" }}
                >
                  Comparar
                </button>
              </LoadsWithSpinner>
            </div>
          </div>
        </LoadsWithSpinner>
      </div>
      {comparador &&
        comparando.length > 0 &&
        listosParaComparar == comparando.length && (
          <div className="container-fluid">
            {
              //<div style={{ width: '100%', margin: '0 auto', textAlign: 'right', paddingRight: 20 }}><button onClick={reiniciar} className="btn btn-primary">Volver a gestión de estudios</button></div>
            }
            <div className="row" style={{ padding: 20 }}>
              {comparador.estudiosParaTabla().map((estudio) => (
                <TablaEstudio estudio={estudio} />
              ))}
            </div>
            <div className="row" style={{ padding: 20 }}>
              {comparador.dataParaCharts(campoActual).map((chart) => (
                <div
                  className={
                    "col-sm-" +
                    (chart.esUnico ? "12" : "6") +
                    " col-md-" +
                    (chart.esUnico ? "12" : "6") +
                    " col-lg-" +
                    (chart.esUnico ? "12" : "6") +
                    " pricingTable purple"
                  }
                >
                  <div className="pricingTable-header">
                    <h3>{chart.tipoCampo.titulo}</h3>
                  </div>
                  <div
                    className="pricingContent"
                    style={{ height: 300, marginBottom: 30 }}
                  >
                    <ResponsiveContainer>
                      <LineChart
                        data={chart.data}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 30,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          type="number"
                          domain={["dataMin", "dataMax"]}
                          tickFormatter={formatXAxis}
                        />
                        <YAxis dataKey="valor" unit={chart.tipoCampo.unidad} />
                        <Tooltip
                          formatter={(value) => value + chart.tipoCampo.unidad}
                          labelFormatter={(value, name, props) => [
                            "",
                            moment(new Date(value)).format("D MMM YYYY"),
                          ]}
                        />
                        <Legend />
                        <Line
                          connectNulls
                          type="monotone"
                          dot={{ r: 6 }}
                          dataKey="valor"
                          name={chart.tipoCampo.titulo}
                          stroke="#8884d8"
                          strokeWidth="3"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
}

function isEquivalent(a, b) {
  if (!a || !b) {
    return false;
  }
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}
