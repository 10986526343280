import Consultation from './Consultation'
import { jsonFetchPromise } from './FetchHelper'

export class Consultations {
    constructor() {
        this._idPaciente = ''
        this._consultations = []
    }

    set consultations(consultations) { this._consultations = consultations }
    get consultations() { return this._consultations }
    set idPaciente(idPaciente) { this._idPaciente = idPaciente }
    get idPaciente() { return this._idPaciente }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes/'+this._idPaciente+'/consultas',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const obj = json
                that._consultations = obj.map((consultation) => Object.assign(new Consultation(), consultation))
            },
            jwt)
    }
}