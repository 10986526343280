import React, { useState, useEffect, useContext } from 'react'
import './Estudio.css'
import Archivo from '../classes/Archivo'
import moment from 'moment'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useRouteMatch } from "react-router-dom";
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'

import 'moment/locale/es'  // without this line it didn't work



import LoadsWithSpinner from './LoadsWithSpinner'
import Archivos from '../classes/Archivos'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

moment.locale('es')

const columns = [
    {
        label: 'Nombre',
        field: 'nombre',
    },
    {
        label: 'Descripcion',
        field: 'descripcion',
    },

    {
        label: 'Archivo',
        field: 'archivo',
    }
    ,
    {
        label: '',
        field: 'edit',
    }
]

export default function ArchivosTable() {
    let { url } = useRouteMatch();
    const { JWT, logoutHandler } = useContext(AuthContext)

    const [archivos, setArchivos] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [loadingArchivos, setLoadingArchivos] = useState(false)


    const deleteArchivo = (idArchivo,nombre) => {

        MySwal.fire({
            title: 'Va a borrar el archivo ' + nombre,
            text: "¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Borrar'
        }).then((result) => {
            if (result.value) {
                const archivoABorrar = new Archivo(idArchivo)
                archivoABorrar.delete(JWT)
                    .then(() => {
                        MySwal.fire(
                            'Borrado exitosamente',
                            '',
                            'success'
                        )
                        refreshArchivos()

                    })
                    .catch((error) => {
                        mySwallFetchErrorHandler(error, logoutHandler)
                    })

            }
        })
    }


    useEffect(() => {
        setDataTable(
            archivos.map(archivo => {
                return {
                    nomrbre: archivo.nombre(),
                    descripcion: archivo.descripcion(),
                    archivo: archivo.urlArchivo() ? <a href={archivo.urlArchivo()} target="_blank" rel="noopener noreferrer">Descargar</a> : "-",
                    edit: <><Link to={`${url}/` + archivo.idArchivo() + `/edit/`} ><FontAwesomeIcon icon="edit" /></Link>
                        <a onClick={() => deleteArchivo(archivo.idArchivo(), archivo.nombre())} style={{ cursor: 'pointer', marginLeft: 10, color: 'darkred' }}><FontAwesomeIcon icon="trash" /></a></>

                }
            }))
    }, [archivos])

    const refreshArchivos = () => {
        setLoadingArchivos(true)
        const archivosRepo = new Archivos()
        archivosRepo.getAll(JWT)
            .then(() => {
                setArchivos(archivosRepo.archivos)
                setLoadingArchivos(false)
            })
            .catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler);
                setLoadingArchivos(false)
            });
    }


    useEffect(() => {
        refreshArchivos()
    }, [])




    return (

            <div className="container-fluid">
            <div className="d-sm-flex justify-content-between align-items-center mb-4">
                </div>
                <LoadsWithSpinner variable={loadingArchivos} text="Cargando Archivos">
                    <div className="card shadow">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col">
                                    <p className="text-primary m-0 font-weight-bold" style={{ fontSize: '25px' }}>Archivos</p>
                                </div>
                                <div className="col-lg-3">
                                    <Link to={`${url}/new`}>
                                        <h6 className="float-right">Nuevo Archivo&nbsp;&nbsp; <button className="btn btn-primary text-center text-white rounded-circle" style={{ fontSize: '15px' }}><FontAwesomeIcon icon="plus" /></button></h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" >
                            <form>
                                <MDBTable btn fixed>
                                    <MDBTableHead columns={columns} />
                                    <MDBTableBody rows={dataTable} />
                                </MDBTable>
                            </form>
                        </div>
                    </div>
                </LoadsWithSpinner>
            </div>

    )
}

