import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { jsonFetchPromise } from './classes/FetchHelper'
import './App.css';
import mySwallFetchErrorHandler from './components/mySwallFetchErrorHandler'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LoadsWithSpinner from './components/LoadsWithSpinner'

import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';
import LogIn from './LogIn'

import { AuthProvider } from './AuthContext'
import { useCookies } from 'react-cookie';

import Consultation from './components/Consultation'
import { ArchivosAdmin } from './components/ArchivosAdmin';

const MySwal = withReactContent(Swal)

function App() {

  const [auth, setAuth] = useState(true)
  const [loadingAuth, setLoadingAuth] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies();
  let location = useLocation();

  const logoutHandler = () => {
    removeCookie('JWT')
    setAuth(false)
  }

  const loginHandler = (usuario, pw) => {
    setLoadingAuth(true)
    const loginData = { usuario: usuario, pw: pw }

    jsonFetchPromise('https://api.medicinafuncional.com.ar/login', 'POST', {}, () => { },null,JSON.stringify(loginData))
      .then((respuesta) => {
        setCookie('JWT',respuesta.token,{
          maxAge: respuesta.expires_in,
          path: '/'
       })
        setLoadingAuth(false)
        setAuth(true)
      })
      .catch((error) => {
        mySwallFetchErrorHandler(error, () => {
          MySwal.fire({
            icon: 'error',
            html: <p>Usuario y/o contraseña inválido</p>,
          })
        })
        setLoadingAuth(false)
      })
  }

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }

  useEffect(()=>{
    
    if(!cookies.JWT){
      setAuth(false)
    }
    
  },[location])

  return (
    <AuthProvider value={{logoutHandler:logoutHandler, JWT:cookies.JWT}}>
        <Switch>
          <Route exact path="/">
            {auth && <Redirect to={{ pathname: "/pacientes" }} />}
            <LoadsWithSpinner variable={loadingAuth}>
              <LogIn loginHandler={loginHandler} />
            </LoadsWithSpinner>
          </Route>
          <PrivateRoute path="/pacientes">
            <div id="wrapper" className="wrapper">
              <Header/>
              <div className="d-flex flex-column" id="content-wrapper">
                <Body logoutHandler={logoutHandler}/>
                <Footer />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute path="/archivos">
            <div id="wrapper" className="wrapper">
              <Header/>
              <div className="d-flex flex-column" id="content-wrapper">
                <ArchivosAdmin logoutHandler={logoutHandler}/>
                <Footer />
              </div>
            </div>
          </PrivateRoute>
          <Route path="*">
            <h1>404 NOT FOUND</h1>
          </Route>
        </Switch>
    </AuthProvider>
  );
}

export default App;
