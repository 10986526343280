import React, { useState, useEffect, useContext } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'
import LoadsWithSpinner from './LoadsWithSpinner';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function RichText(props) {
    const { JWT, logoutHandler } = useContext(AuthContext)


    const [text, setText] = useState(props.body)
    const [savedText, setSavedText] = useState(props.body)
    const [editing, setEditing] = useState(props.enabled)
    const [loading, setLoading] = useState(false)
    const [destacadoText, setDestacadoText] = useState("")
    const [destacado, setDestacado] = useState(props.estaDestacado != 0)

    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);

    const editorStateChange = () => {
        setEditing(!editing)
    }

    const handleChange = (newText) => {
        setText(newText)
    }

    const handleDestacar = () => {
        if (destacadoText.trim() !== "") {
            toggle()
            setDestacado(true)
            const updater = props.destacarHandler(destacadoText)
            updater.cb()
            updater.update.then((respuesta) => {
                props.refreshDestacados()
            }).catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setDestacado(false)
            })
        }
    }

    const handleDelete = () => {

        MySwal.fire({
            title: 'Va a borrar el item ' + props.titulo,
            text: "¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Borrar'
        }).then((result) => {
            if (result.value) {
                props.deleteHandler()
            }
        })
    }

    useEffect(() => {
        if (!editing && text != null && savedText != text) {
            setLoading(true)
            const updater = props.updateHandler(text)
            updater.cb()
            updater.update.then((respuesta) => {
                setSavedText(text)
                setLoading(false)
            }).catch((error) => {
                mySwallFetchErrorHandler(error, logoutHandler)
                setEditing(true)
                setLoading(false)
            })
            return function cleanUp() {
                updater.cb()
            }
        }
    }, [editing, text])

    return (
        <div className="col">
            <div className="card mb-4">
                <div className="card-header py-3 border-left-info" style={{ background: '#fff' }}>
                    <h5 className="text-dark font-weight-bold h5 mb-0">{props.titulo}
                        {props.deleteHandler && <button className="btn btn-default btn-m border" style={{ float: "right" }} onClick={handleDelete}><FontAwesomeIcon icon="trash" /></button>}

                        {!editing && <button className="btn btn-default btn-m border" style={{ float: "right" }} onClick={editorStateChange}><FontAwesomeIcon icon="edit" /></button>}
                        {!editing && !destacado && !["Objetivo", "Tratamiento"].includes(props.titulo) && <>
                            <FontAwesomeIcon icon="star" id={"Popover" + props.idItemConsulta} type="button" style={{ float: 'right', margin: 8, color: '#999999' }} />
                            <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy" placement="bottom" isOpen={popoverOpen} target={"Popover" + props.idItemConsulta} toggle={toggle}>
                                <PopoverHeader>Razón</PopoverHeader>
                                <PopoverBody>
                                    <input type="text" onKeyDown={e => e.key === 'Enter' && handleDestacar()} onChange={(event) => setDestacadoText(event.target.value)} value={destacadoText} className="form-control" />
                                    <Button type="button" className="btn-info" onClick={handleDestacar} >
                                        Ok!
                                    </Button>
                                </PopoverBody>
                            </UncontrolledPopover></>}
                        {destacado && !["Objetivo", "Tratamiento"].includes(props.titulo) && <FontAwesomeIcon icon="star" style={{ float: 'right', margin: 8, color: '#f6c23e' }} />}
                    </h5>
                </div>
                <div className="card-body" style={{ padding: 0 }}>
                    <SunEditor
                        lang="es"
                        onChange={(content) => handleChange(content)}
                        setContents={props.body ? props.body : ''}
                        setDefaultStyle=" line-height:1.3;word-break: normal;font-size: 16px;font-family:Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji"
                        hide={!editing}
                        setOptions={{
                            buttonList: [["undo",
                                "redo",
                            ], [
                                'fontSize',
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "fontColor",
                                "hiliteColor",], [
                                "outdent",
                                "indent",
                                "align"
                            ], [
                                "horizontalRule",
                                "list",
                                "table",
                            ], [
                                "link",
                                "image",
                                "video",
                            ], [
                                "print"]]
                        }} />
                    {!editing && <div dangerouslySetInnerHTML={{ __html: text || props.body }} style={{ padding: 20, lineHeight: 1.3 }}></div>}
                    <LoadsWithSpinner variable={loading} height="50" text="Guardando ">
                        {editing && <button className="btn btn-success" style={{ width: '100%' }} onClick={editorStateChange}>Listo</button>}
                    </LoadsWithSpinner>
                </div>
            </div>
        </div>
    )
}