import { jsonFetchPromise } from './FetchHelper'


export default class Archivo {

    #idArchivo = ''
    #nombre = ''
    #descripcion = ''
    #urlArchivo = ''

    constructor(idArchivo = '', nombre = '', descripcion = '', urlArchivo = '') {
        this.#idArchivo = idArchivo
        this.#nombre = nombre
        this.#descripcion = descripcion
        this.#urlArchivo = urlArchivo
    }




    descripcion() { return this.#descripcion }
    nombre() { return this.#nombre }
    idArchivo() { return this.#idArchivo }

    urlArchivo() {

        if (!this.#urlArchivo) {
            return ''
        }
        return "https://api.medicinafuncional.com.ar/uploads/" + this.#urlArchivo
    }





    createFormData(edit = false) {
        const formData = new FormData();
        formData.append('descripcion', this.#descripcion)
        formData.append('nombre', this.#nombre)
        formData.append('archivo', this.#urlArchivo)
        return formData
    }

    update(jwt) {
        if (this.#idArchivo) {
            return this.editar(jwt)
        } else {
            return this.crear(jwt)
        }
    }

    crear(jwt) {
        const formData = this.createFormData()
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/archivos', 'POST', {}, (json) => { }, jwt, formData)
    }
    sendEmail(jwt, idPaciente) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/pacientes/' + idPaciente + "/archivos/" + this.#idArchivo + "/sendfile", 'POST', {}, (json) => { }, jwt)
    }

    editar(jwt) {
        const formData = this.createFormData(true)
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/archivos/' + this.#idArchivo, 'PUT', {}, (json) => { }, jwt, formData)
    }

    get(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/archivos/' + this.#idArchivo, 'GET', {},
            ({ idArchivo, nombre, descripcion, urlArchivo }) => {
                this.#idArchivo = idArchivo
                this.#nombre = nombre
                this.#descripcion = descripcion
                this.#urlArchivo = urlArchivo
            }, jwt)
    }

    delete(jwt) {
        return jsonFetchPromise('https://api.medicinafuncional.com.ar/archivos/' + this.#idArchivo, 'DELETE', {}, (json) => { }, jwt)
    }


}