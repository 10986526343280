import React, { useState, useEffect, useContext } from 'react'
import LoadsWithSpinner from './LoadsWithSpinner'
import mySwallFetchErrorHandler from './mySwallFetchErrorHandler'
import AuthContext from '../AuthContext'
import PlanContext from './PlanContext'
export default function PlanItem(props) {
    const { JWT, logoutHandler } = useContext(AuthContext)

    const updateGlobal = useContext(PlanContext) 
    const [done, setDone] = useState(props.isCompleted != 0)
    const [loading, setLoading] = useState(false)
    const handleClick = (e) => {
        const newDone = !done
        setDone(newDone)
        props.handleStatusChange(newDone)
        !props.global&&setTimeout(updateGlobal, 1000);
    }

    const deleteItem = () => {
        setLoading(true)
        props.handleDelete()
    }


    return (
        <li className="list-group-item" style={done ? { backgroundColor: "#f7f9ff" } : null}>
            <div className="row align-items-center no-gutters">
                <div className="col-lg-2">
                    <div className="custom-control custom-checkbox d-inline-block">
                        <input className="custom-control-input"
                            type="checkbox" id={"plan-item-" + props.idItemplan + (props.global ? '_global' : '')} onChange={handleClick} checked={done} />
                        <label className="custom-control-label"
                            htmlFor={"plan-item-" + props.idItemplan + (props.global ? '_global' : '')}></label>
                    </div>
                </div>
                <div className="col mr-2" style={{ cursor: 'pointer' }} onClick={handleClick}>
                    <a style={done ? { textDecoration: "line-through" } : null}>
                        <h6 className="mb-0" style={done ? { color: "#999999" } : null}>{props.titulo}</h6>
                    </a>
                </div>
                {!props.global &&
                <div className="col-auto">
                    <LoadsWithSpinner variable={loading} height="50">
                        <button className="btn btn-default btn-sm" onClick={deleteItem} style={{ fontSize: 20 }}>x</button>
                    </LoadsWithSpinner>
                </div>}
            </div>
        </li>
    )
}