import Patient from './Patient'
import { jsonFetchPromise } from './FetchHelper'

export default class Patients {
    constructor() {
        this._patients = []
    }

    set patients(patients) { this._patients = patients }
    get patients() { return this._patients }

    getAll(jwt) {
        var that = this

        return jsonFetchPromise(
            'https://api.medicinafuncional.com.ar/pacientes',
            'GET',
            { "Accept": "application/json" },
            (json) => {
                const obj = json
                that._patients = obj.map((patient) => Object.assign(new Patient(), patient))
            },
            jwt)
    }
}